<template>
	<footer class="py-3 footer">
		<div class="container-fluid">
			<div class="row align-items-center justify-content-lg-between">
				<div class="mb-4 col-lg-6 mb-lg-0">
					<div class="text-sm text-center copyright text-muted"
						:class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">
						Copyright ©
						{{ new Date().getFullYear() }} by
						<a href="https://www.kuntn.com" class="font-weight-bold" target="_blank">Kuntn</a>.
					</div>
				</div>
				<div class="col-lg-6">
					<ul class="nav nav-footer justify-content-center justify-content-lg-end">
						<li class="nav-item">
							<a href="https://www.kuntn.com" class="nav-link text-muted" target="_blank">鲲盾官网</a>
						</li>
						<li class="nav-item">
							<a href="https://www.kuntn.com" class="nav-link text-muted" target="_blank">关于我们</a>
						</li>
						<li class="nav-item">
							<a href="https://www.kuntn.com" class="nav-link text-muted"
								target="_blank">产品介绍</a>
						</li>
						<li class="nav-item">
							<a href="https://www.kuntn.com" class="nav-link text-muted"
								target="_blank">联系我们</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "MainFooter",
	};
</script>