<template>
	<div class="container position-sticky z-index-sticky">
		<div class="row">
			<div class="col-12">
				<auth-navbar />
			</div>
		</div>
	</div>
	<main class="main-content mt-0">
		<div class="page-header align-items-start min-vh-50 pt-7 pb-9" :style="`
        background-image: url(${require('@/assets/images/signin-cover.jpg')});`">
			<span class="mask bg-gradient-dark opacity-6"></span>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-5 text-center mx-auto">
						<h1 class="text-white mb-2 mt-5">登陆</h1>
						<p class="text-lead text-white">
							鲲盾商户管理后台
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
				<div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
					<div class="card mt-5">
						<div class="card-header pb-0 text-start">
							<h3 class="font-weight-bolder">欢迎回来</h3>
							<p class="mb-0">请输入您的电子邮箱和密码</p>
						</div>
						<div class="card-body">
							<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
								role="alert">
								<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
								<span class="alert-text">{{ errorTip }}</span>
								<button type="button" class="btn-close py-2" @click="errorTip = ''">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form role="form" class="text-start">
								<label>邮箱</label>
								<argon-input id="email" type="email" placeholder="Email" aria-label="Email" v-model="email" />
								<label>密码</label>
								<argon-input id="password" type="password" placeholder="密码" aria-label="Password" v-model="password" />
								<argon-switch id="rememberMe" name="rememberMe">
									记住我
								</argon-switch>
								<div class="text-center">
									<argon-button color="primary" variant="gradient" full-width class="mt-4 mb-0" @click="signin($event)" :disabled="isSignin"><span v-if="isSignin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 登陆</argon-button>
								</div>
							</form>
						</div>
						<div class="card-footer text-center pt-0 px-lg-2 px-1">
							<p class="mb-4 text-sm mx-auto">
								没有商户账号?
								<router-link :to="{ name: '注册' }" class="text-success font-weight-bold">注册</router-link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
	<auth-footer />
</template>

<script>
	import AuthNavbar from "@/components/Auth/Navbar.vue"; // 头部导航
	import AuthFooter from "@/components/Auth/Footer.vue"; // 底部
	import ArgonInput from "@/components/ArgonInput.vue"; // 输入框
	import ArgonSwitch from "@/components/ArgonSwitch.vue"; // 开关
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	const body = document.getElementsByTagName("body")[0];
	import {
		mapMutations
	} from "vuex";

	export default {
		name: "Signin",
		components: {
			AuthNavbar,
			AuthFooter,
			ArgonInput,
			ArgonSwitch,
			ArgonButton,
		},
		data() {
		  return {
		    email: "",
		    password: "",
			errorTip: "",
			isSignin: false
		  };
		},
		created() {
			this.toggleDefaultLayout();
			body.classList.remove("bg-gray-100");
		},
		beforeUnmount() {
			this.toggleDefaultLayout();
			body.classList.add("bg-gray-100");
		},
		methods: {
			...mapMutations(["toggleDefaultLayout"]),
			async signin(event) { // 登陆
				event.preventDefault(); // 防止表单提交
				this.errorTip = "";
				
				if (this.email.length == 0 || !this.checkEmail(this.email)) {
					this.errorTip = "请输入正确的邮箱";
					return;
				} else if (this.password.length == 0) {
					this.errorTip = "请输入密码";
					return;
				}
				
				this.isSignin = true;
				await this.$kuntnApi.signin({
					email: this.email,
					verify: this.password,
					verify_type: 'password',
				}).catch(err => {
					this.errorTip = err;
					this.isSignin = false;
				})
			},
			checkEmail(email) { // 检查邮箱
				let regEmail = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
				if (!regEmail.test(email)) {
					return false;
				}
				return true;
			}
		},
	};
</script>