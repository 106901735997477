<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="mx-auto col-lg-9 col-12">
				<div class="mt-4 card card-body">
					<h6 class="mb-0">编辑商家</h6>
					<p class="mb-0 text-sm">添加的商家通过审核后将自动进行预警监控</p>
					<hr class="my-3 horizontal light" />
					<div class="d-flex align-items-center">
						<div class="text-center d-flex align-items-center w-20">
							<i class="ni ni-circle-08 text-lg opacity-6"></i>
							<p class="my-auto ms-3">公司名称</p>
						</div>
						<div class="d-flex align-items-center w-80">
							<p class="text-secondary text-sm ms-auto my-auto me-3">{{ accountInfo.company_name }}</p>
							<span class="badge ms-2"
								:class="getEthocaStatus(accountInfo.ethoca_status).className">{{ getEthocaStatus(accountInfo.ethoca_status).des }}</span>
							<span class="badge ms-2"
								:class="getRDRStatus(accountInfo.rdr_status).className">{{ getRDRStatus(accountInfo.rdr_status).des }}</span>
						</div>
					</div>
					<div class="d-flex align-items-center mt-3">
						<div class="text-center d-flex align-items-center w-20">
							<i class="ni ni-money-coins text-lg opacity-6"></i>
							<p class="my-auto ms-3">Ethoca手续费</p>
						</div>
						<div class="d-flex align-items-center w-80">
							<p class="text-secondary text-sm ms-auto my-auto me-3">$ {{ accountInfo.ethoca_fee }}</p>
						</div>
					</div>
					<div class="d-flex align-items-center mt-3">
						<div class="text-center d-flex align-items-center w-20">
							<i class="ni ni-money-coins text-lg opacity-6"></i>
							<p class="my-auto ms-3">VISA RDR手续费</p>
						</div>
						<div class="d-flex align-items-center w-80">
							<p class="text-secondary text-sm ms-auto my-auto me-3">$ {{ accountInfo.rdr_fee }}</p>
						</div>
					</div>
					<hr class="my-3 horizontal light" />
					<div class="mt-4 row">
						<div class="d-flex">
							<argon-avatar :img="require('@/assets/images/ethoca-lg.jpg')" alt="Ethoca" size="lg"
								border-radius="md" />
							<div class="my-auto ms-3">
								<div class="h-100">
									<h5 class="mb-0">Ethoca</h5>
									<a class="text-sm text-body" href="javascript:;">
										主要预警MasterCard拒付，同时可预警其他卡品牌
										<i :class="`fas fa-chevron-${ethocaChecked ? 'up' : 'down'} text-xs ms-1`"
											aria-hidden="true"></i>
									</a>
								</div>
							</div>
							<p class="text-sm text-secondary ms-auto me-3 my-auto">启用</p>
							<div class="my-auto">
								<argon-switch id="enable-ethoca" name="Enabled"
									@click="accountInfo.ethoca_status != 0 ? true : this.ethocaChecked = !this.ethocaChecked"
									:checked="ethocaChecked"
									:disabled="accountInfo.ethoca_status != 0 ? true : false" />
							</div>
						</div>
						<div class="px-5 pt-3 ms-3" v-if="ethocaChecked">
							<p class="mb-0 text-sm">
								如果同时开启了VISA快速争议处理，则在产生VISA拒付时可能会同时计费且不可避免，但如果在Ethoca收到预警时立即处理Ethoca的退款，则可最大程度上避免重复计费
							</p>
							<div class="d-sm-flex align-items-center bg-dark border-radius-lg p-2 mt-4">
								<p class="text-sm font-weight-bold my-auto ps-sm-2">
									Ethoca描述符(Descriptor)
								</p>
								<div class="input-group my-auto w-60 ms-sm-auto">
									<a v-if="accountInfo.ethoca_status != 0 ? true : false"
										:href="`/account/ethoca-descriptor-list/${accountInfo.id}`"
										class="text-primary text-sm icon-move-right my-auto ms-auto pe-2">
										查看描述符
										<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
									</a>
									<div v-else class="input-group my-auto ms-sm-auto">
										<input type="text" class="form-control" placeholder="Ethoca描述符(Descriptor)"
											aria-label="Ethoca描述符(Descriptor)" v-model="ethocaDescriptor">
										<div class="dropdown">
											<argon-button id="navbarDropdownMenuLink1" color="primary"
												variant="gradient" size="md"
												class="dropdown-toggle border-top-start-radius-0 border-bottom-start-radius-0"
												data-bs-toggle="dropdown"
												aria-expanded="false">{{ ethocaMatchModelDes }}</argon-button>
											<ul class="dropdown-menu dropdown-menu-lg-start px-1 py-1 bg-white"
												aria-labelledby="navbarDropdownMenuLink1" style>
												<li @click="selectMatchModel('ethoca', 'exact')">
													<a class="dropdown-item border-radius-md"
														href="javascript:;">精准匹配</a>
												</li>
												<li @click="selectMatchModel('ethoca', 'start')">
													<a class="dropdown-item border-radius-md"
														href="javascript:;">以开头匹配</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr class="my-4 horizontal light" />
						<div class="d-flex">
							<argon-avatar :img="require('@/assets/images/rdr-lg.jpg')" alt="Ethoca" size="lg"
								border-radius="md" />
							<div class="my-auto ms-3">
								<div class="h-100">
									<h5 class="mb-0">VISA快速争议处理</h5>
									<a class="text-sm text-body" href="javascript:;">
										仅预警VISA拒付，且会自动退款以快速解决争议
										<i :class="`fas fa-chevron-${rdrChecked ? 'up' : 'down'} text-xs ms-1`"
											aria-hidden="true"></i>
									</a>
								</div>
							</div>
							<p class="text-sm text-secondary ms-auto me-3 my-auto">启用</p>
							<div class="my-auto">
								<argon-switch id="enable-rdr" name="Enabled"
									@click="accountInfo.rdr_status != 0 ? true : this.rdrChecked = !this.rdrChecked"
									:checked="rdrChecked" :disabled="accountInfo.rdr_status != 0 ? true : false" />

							</div>
						</div>
						<div class="px-5 pt-3 ms-3" v-if="rdrChecked">
							<p class="mb-0 text-sm">
								如果同时开启了Ethoca，则在产生VISA拒付时可能会同时计费且不可避免，但如果在Ethoca收到预警时立即处理Ethoca的退款，则可最大程度上避免重复计费
							</p>
							<div class="d-sm-flex align-items-center bg-dark border-radius-lg p-2 my-4">
								<p class="text-sm font-weight-bold my-auto ps-sm-2">
									收单机构名称
								</p>
								<span v-if="accountInfo.rdr_status != 0 ? true : false"
									class="my-auto w-60 ms-sm-auto text-end pe-3">{{ accountInfo.rdr_acquirer_name }}</span>
								<argon-input v-else id="rdrAcquirerName" type="text" class="my-auto w-60 ms-sm-auto"
									placeholder="收单机构名称" v-model="rdrAcquirerName" />
							</div>
							<div class="d-sm-flex align-items-center bg-dark border-radius-lg p-2 my-4">
								<p class="text-sm font-weight-bold my-auto ps-sm-2">
									VISA BIN
								</p>
								<span v-if="accountInfo.rdr_status != 0 ? true : false"
									class="my-auto w-60 ms-sm-auto text-end pe-3">{{ accountInfo.rdr_acquirer_bin }}</span>
								<argon-input v-else id="rdrAcquirerBin" type="text" class="my-auto w-60 ms-sm-auto"
									placeholder="VISA BIN" v-model="rdrAcquirerBin" />
							</div>
							<div class="d-sm-flex align-items-center bg-dark border-radius-lg p-2 my-4">
								<p class="text-sm font-weight-bold my-auto ps-sm-2">
									银行商户号(CAID)
								</p>
								<span v-if="accountInfo.rdr_status != 0 ? true : false"
									class="my-auto w-60 ms-sm-auto text-end pe-3">{{ accountInfo.rdr_caid }}</span>
								<argon-input v-else id="rdrCaid" type="text" class="my-auto w-60 ms-sm-auto"
									placeholder="银行商户号(CAID)" v-model="rdrCaid" />
							</div>
							<div class="d-sm-flex align-items-center bg-dark border-radius-lg p-2 my-4">
								<p class="text-sm font-weight-bold my-auto ps-sm-2">
									VISA快速争议处理描述符(Descriptor)
								</p>
								<div class="input-group my-auto w-60 ms-sm-auto">
									<a v-if="accountInfo.rdr_status != 0 ? true : false"
										:href="`/account/rdr-descriptor-list/${accountInfo.id}`"
										class="text-primary text-sm icon-move-right my-auto ms-auto pe-3">
										查看描述符
										<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
									</a>
									<div v-else class="input-group my-auto ms-sm-auto">
										<input type="text" class="form-control" placeholder="VISA快速争议处理描述符(Descriptor)"
											aria-label="VISA快速争议处理描述符(Descriptor)" v-model="rdrDescriptor">
										<div class="dropdown">
											<argon-button id="navbarDropdownMenuLink2" color="primary"
												variant="gradient" size="md"
												class="dropdown-toggle border-top-start-radius-0 border-bottom-start-radius-0"
												data-bs-toggle="dropdown"
												aria-expanded="false">{{ rdrMatchModelDes }}</argon-button>
											<ul class="dropdown-menu dropdown-menu-lg-start px-1 py-1 bg-white"
												aria-labelledby="navbarDropdownMenuLink2" style>
												<li @click="selectMatchModel('rdr', 'exact')">
													<a class="dropdown-item border-radius-md"
														href="javascript:;">精准匹配</a>
												</li>
												<li @click="selectMatchModel('rdr', 'start')">
													<a class="dropdown-item border-radius-md"
														href="javascript:;">以开头匹配</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-4">
							<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
								role="alert">
								<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
								<span class="alert-text">{{ errorTip }}</span>
								<button type="button" class="btn-close py-2" @click="errorTip = ''">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
						<div class="mt-4 d-flex justify-content-end">
							<button type="button" name="button" class="m-0 btn btn-light"
								@click="$router.push({path:'/account/account-list'});">
								取消
							</button>
							<argon-button color="primary" variant="gradient" class="m-0 ms-2"
								@click="updateAccount($event)" :disabled="isUpdateAccount"><span v-if="isUpdateAccount"
									class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								修改</argon-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import ArgonSwitch from "@/components/ArgonSwitch.vue"; // 开关
	import ArgonAvatar from "@/components/ArgonAvatar.vue"; // 头像
	import ArgonInput from "@/components/ArgonInput.vue"; // 输入框

	export default {
		name: "EditAccount",
		components: {
			ArgonButton,
			ArgonSwitch,
			ArgonAvatar,
			ArgonInput,
		},
		data() {
			return {
				accountInfo: {},
				ethocaChecked: false,
				ethocaMatchModel: "exact",
				ethocaMatchModelDes: "精准匹配",
				rdrChecked: false,
				rdrMatchModel: "exact",
				rdrMatchModelDes: "精准匹配",
				errorTip: "",
				companyName: "",
				ethocaDescriptor: "",
				rdrAcquirerName: "",
				rdrAcquirerBin: "",
				rdrCaid: "",
				rdrDescriptor: "",
				isUpdateAccount: false,
			}
		},
		methods: {
			async initializeData() { // 初始化数据
				await this.$kuntnApi.getAccount({
					account_id: this.$route.params.id,
				}).then((result) => { // 获商家信息
					this.accountInfo = result;
					this.ethocaChecked = result.ethoca_status != 0 ? true : false;
					this.rdrChecked = result.rdr_status != 0 ? true : false;
				}).catch(err => {
					console.log(err);
				})
			},
			async updateAccount(event) { // 修改商家
				event.preventDefault(); // 防止表单提交
				this.errorTip = "";

				if (this.ethocaChecked && this.accountInfo.ethoca_status == 0) {
					if (this.ethocaDescriptor.length == 0) {
						this.errorTip = "请输入Ethoca描述符";
						return;
					}
				}
				if (this.rdrChecked && this.accountInfo.rdr_status == 0) {
					if (this.rdrAcquirerName.length == 0) {
						this.errorTip = "请输入收单机构名称";
						return;
					} else if (this.rdrAcquirerBin.length == 0) {
						this.errorTip = "请输入VISA BIN";
						return;
					} else if (this.rdrCaid.length == 0) {
						this.errorTip = "请输入银行商户号";
						return;
					} else if (this.rdrDescriptor.length == 0) {
						this.errorTip = "VISA快速争议处理描述符";
						return;
					}
				}

				this.isUpdateAccount = true;
				await this.$kuntnApi.updateAccount({
					account_id: this.$route.params.id,
					ethoca_descriptor: this.ethocaDescriptor,
					ethoca_match_model: this.ethocaChecked ? this.ethocaMatchModel : "",
					is_ethoca_open: this.ethocaChecked ? 1 : 0,
					rdr_acquirer_name: this.rdrAcquirerName,
					rdr_acquirer_bin: this.rdrAcquirerBin,
					rdr_caid: this.rdrCaid,
					rdr_descriptor: this.rdrDescriptor,
					rdr_match_model: this.rdrChecked ? this.rdrMatchModel : "",
					is_rdr_open: this.rdrChecked ? 1 : 0,
				}).then(() => {
					this.$router.push({
						path: '/account/account-list'
					});
				}).catch(err => {
					this.errorTip = err;
					this.isUpdateAccount = false;
				})
			},
			selectMatchModel(type, model) {
				switch (type) {
					case "ethoca":
						this.ethocaMatchModel = model;
						this.ethocaMatchModelDes = this.getMatchModelDes(model);
						break;
					case "rdr":
						this.rdrMatchModel = model;
						this.rdrMatchModelDes = this.getMatchModelDes(model);
						break;
				}
			},
			getMatchModelDes(model) {
				switch (model) {
					case "exact":
						return "精准匹配";
					case "start":
						return "以开头匹配";
				}
			},
			getEthocaStatus(ethocaStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (ethocaStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					status_obj.des = "Ethoca未开启";
					status_obj.className = "badge-secondary";
				} else if (ethocaStatus == 1) {
					status_obj.des = "Ethoca预警中";
					status_obj.className = "badge-success";
				} else if (ethocaStatus == 2) {
					status_obj.des = "Ethoca审核中";
					status_obj.className = "badge-warning";
				} else if (ethocaStatus == 3) {
					status_obj.des = "Ethoca审核失败";
					status_obj.className = "badge-danger";
				} else if (ethocaStatus == 4) {
					status_obj.des = "Ethoca关闭中";
					status_obj.className = "badge-secondary";
				}
				console.log(ethocaStatus)
				return status_obj;
			},
			getRDRStatus(rdrStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (rdrStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					status_obj.des = "快速争议处理未开启";
					status_obj.className = "badge-secondary";
				} else if (rdrStatus == 1) {
					status_obj.des = "快速争议处理预警中";
					status_obj.className = "badge-success";
				} else if (rdrStatus == 2) {
					status_obj.des = "快速争议处理审核中";
					status_obj.className = "badge-warning";
				} else if (rdrStatus == 3) {
					status_obj.des = "快速争议处理审核失败";
					status_obj.className = "badge-danger";
				} else if (rdrStatus == 4) {
					status_obj.des = "快速争议处理关闭中";
					status_obj.className = "badge-secondary";
				}
				return status_obj;
			},
		},
		mounted() {
			this.initializeData();
		},
		beforeMount() {
			this.$store.state.layout = "custom"; // 显示顶部背景图
		},
		beforeUnmount() {
			this.$store.state.layout = "default"; // 不显示顶部背景图
		},
	};
</script>