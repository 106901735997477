<template>
	<div class="py-4 container-fluid">
		<div class="mt-4 row">
			<div class="col-12">
				<div class="card shadow-lg">
					<img src="@/assets/images/pattern-left.png" alt="pattern-lines"
						class="position-absolute overflow-hidden opacity-4 start-0 top-0 h-100 border-radius-xl" />
					<img src="@/assets/images/pattern-right.png" alt="pattern-lines"
						class="position-absolute overflow-hidden opacity-4 end-0 top-0 h-100 border-radius-xl" />
					<div class="card-body px-5 z-index-1 bg-cover">
						<div class="row">
							<div class="col-lg-3 col-12 my-auto">
								<h4 class="text-body opacity-9 text-center">24h拒付订单</h4>
								<hr class="horizontal light mt-1 mb-3" />
								<div class="d-flex justify-content-center">
									<div>
										<h6 class="mb-0 text-body opacity-7">未处理</h6>
										<h4 class="text-body">
											<countTo :start-val="0" :end-val="unprocessedCount" :duration="10">
											</countTo>
											{{ "" }}
											<small class="text-sm align-top">笔</small>
										</h4>
									</div>
									<div class="ms-lg-6 ms-4 text-center">
										<h6 class="mb-0 text-body opacity-7">已处理</h6>
										<h4 class="text-body">
											<countTo :start-val="0" :end-val="processedCount" :duration="10"></countTo>
											{{ "" }}
											<small class="text-sm align-top">笔</small>
										</h4>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-12 text-center">
								<img class="w-75 w-lg-100 mt-n7 mt-lg-n8 d-none d-md-block"
									src="@/assets/images/index-logo.png" alt="car image" />
								<div class="d-flex align-items-center">
									<h4 class="text-body opacity-7 ms-0 m-md-auto">
										{{ accountList.length == 0 ? "鲲盾拒付预警系统未运行" : "鲲盾拒付预警系统运行中" }}
									</h4>
								</div>
							</div>
							<div class="col-lg-3 col-12 my-auto">
								<h4 class="text-body opacity-9">商户信息</h4>
								<hr class="horizontal light mt-1 mb-3" />
								<div class="d-flex align-items-center">
									<div class="col-10">
										<div class="p-0 card-body">
											<ul class="list-group">
												<li
													class="mb-2 border-0 d-flex justify-content-between border-radius-lg">
													<div class="d-flex align-items-center">
														<div class="d-flex flex-column">
															<h6 class="mb-1 text-xs text-dark opacity-7">商户昵称</h6>
															<span
																class="text-lg"><strong>{{ merchantName }}</strong></span>
														</div>
													</div>
												</li>
												<li
													class="mb-2 border-0 d-flex justify-content-between border-radius-lg">
													<div class="d-flex align-items-center">
														<div class="d-flex flex-column">
															<h6 class="mb-1 text-xs text-dark opacity-7">商户余额</h6>
															<span class="text-lg"><strong>{{ usdBalance }} USD</strong></span>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>

									<div class="col-2">
										<button class="btn btn-icon-only btn-rounded btn-outline-dark mb-0 py-0" @click="$router.push({path:'/merchant/merchant-center'});">
											<i class="ni ni-map-big" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-4 row">
			<div class="col-lg-3 col-md-6 col-12">
				<mini-statistics-card class="bg-primary" :title="{ text: '今日拒付金额', color: 'opacity-7 text-white' }"
					:value="{ text: todayAmount + ' USD', color: 'text-white' }" :icon="{
            component: 'ni ni-money-coins text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }" />
			</div>
			<div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
				<mini-statistics-card class="bg-primary" :title="{ text: '今日拒付订单', color: 'opacity-7 text-white' }"
					:value="{ text:  todayCount + ' 笔', color: 'text-white' }" :icon="{
            component: 'ni ni-bullet-list-67 text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }" />
			</div>
			<div class="mt-4 col-lg-3 col-md-6 col-12 mt-md-0">
							<mini-statistics-card class="bg-primary" :title="{ text: '本月拒付金额', color: 'opacity-7 text-white' }"
								:value="{ text: monthAmount + ' USD', color: 'text-white' }" :icon="{
			  component: 'ni ni-money-coins text-dark',
			  background: 'bg-white',
			  shape: 'rounded-circle',
			}" />
						</div>
			<div class="mt-4 col-lg-3 col-md-6 col-12 mt-lg-0">
				<mini-statistics-card class="bg-primary" :title="{ text: '本月拒付订单', color: 'opacity-7 text-white' }"
					:value="{ text: monthCount + ' 笔', color: 'text-white' }" :icon="{
            component: 'ni ni-bullet-list-67 text-dark',
            background: 'bg-white',
            shape: 'rounded-circle',
          }" />
			</div>
		</div>

		<div class="mt-4 row">
			<div class="col-md-8">
				<chargeback-chart v-if="isChart" id="chart-line" title="最近7天拒付趋势" :chart="chart" />
				<div v-else class="card z-index-2">
					<div class="pb-0 card-header mb-0">
						<h6>最近7天拒付趋势</h6>
					</div>
					<div class="p-3 card-body">
						<div class="chart">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<account-card :members="accountList" />
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-sm-6">
				<mini-order-list title="Ethoca最新预警"
					tooltip="ethoca可处理Master Card及其他卡品牌的交易预警，您必须在24小时内通过商家或网关帐户中发起退款并在Kuntn预警订单中反馈处理结果，超过24小时未反馈的预警将会继续发送到收单行"
					:headers="['订单号', '金额','状态', '时间']" :list="ethocaOrderList" />
			</div>
			<div class="col-sm-6">
				<mini-order-list title="RDR最新预警" tooltip="RDR仅处理visa交易预警且自动退款，这裡显示订单以及处理结果"
					:headers="['订单号', '金额','状态', '时间']" :list="rdrOrderList" />
			</div>
		</div>
	</div>

</template>
<script>
	import {
		dateToString
	} from "@/utils/tools.js";
	import moment from "moment";
	import MiniStatisticsCard from "@/components/Cards/MiniStatisticsCard.vue"; // 微数据卡片
	import MiniOrderList from "@/components/Lists/MiniOrderList.vue"; // 微订单列表
	import ChargebackChart from "@/components/Charts/ChargebackChart.vue"; // 拒付图表
	import AccountCard from "@/components/Cards/AccountCard.vue"; // 商家卡片
	import setTooltip from "@/assets/js/tooltip.js"; // TIP工具
	import countTo from "vue-count-to/src"; // 数字滚动

	export default {
		name: "Index",
		components: {
			MiniStatisticsCard,
			MiniOrderList,
			ChargebackChart,
			AccountCard,
			countTo,
		},
		data() {
			return {
				merchantName: "加载中...",
				usdBalance: "0.00",
				accountList: [],
				processedCount: 0,
				unprocessedCount: 0,
				todayAmount: 0,
				todayCount: 0,
				monthAmount: 0,
				monthCount: 0,
				ethocaOrderList: [],
				rdrOrderList: [],
				chart: {
					labels: this.getWeekDate(),
					datasets: [{
							label: 'Ethoca订单数',
							data: [0,0,0,0,0,0,0],
						},
						{
							label: 'VISA RDR订单数',
							data: [0,0,0,0,0,0,0],
						},
					],
				},
				isChart: false,
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.getWeekDate();
				this.$kuntnApi.getMerchant({}).then((result) => { // 获取商户信息
					this.merchantName = result.merchant_name;
					this.usdBalance = result.usd_balance;
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getAccountList({ // 获取商家列表
					page: 1,
					size: 4,
				}).then((result) => {
					result.list.forEach((item) => {
						let accountData = {
							info: {
								name: item.company_name,
								link: '/account/account-list'
							},
							status: {
								ethocaStatus: item.ethoca_status,
								rdrStatus: item.rdr_status
							}
						};
						this.accountList.push(accountData);
					});
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getChargeback24h({}).then((result) => { // 获取24小时拒付数据
					this.unprocessedCount = result.unprocessed_count;
					this.processedCount = result.processed_count;
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getChargebackVolume({}).then((result) => { // 获取拒付订单统计
					this.todayAmount = result.today_amount;
					this.todayCount = result.today_count;
					this.monthAmount = result.month_amount;
					this.monthCount = result.month_count;
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getChargebackChart({}).then((result) => { // 获取拒付图表
					this.chart = {
						labels: result.ethoca_chart.date,
						datasets: [{
								label: 'Ethoca订单数',
								data: result.ethoca_chart.count,
							},
							{
								label: 'VISA RDR订单数',
								data: result.rdr_chart.count,
							},
						],
					};
					this.isChart = true;
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getEthocaOrderList({ // 获取Ethoca订单列表
					page: 1,
					size: 7,
					order_status: "all",
				}).then((result) => {
					result.list.forEach((item) => {
						let orderData = {
							orderId: item.order_id,
							amount: item.amount + " " + item.currency,
							createTime: dateToString(item.create_time),
							orderStatus: item.order_status,
						}
						this.ethocaOrderList.push(orderData);
					})
				}).catch(err => {
					console.log(err);
				})

				this.$kuntnApi.getRDROrderList({ // 获取RDR订单列表
					page: 1,
					size: 7,
					order_status: "all",
				}).then((result) => {
					result.list.forEach((item) => {
						let orderData = {
							orderId: item.order_id,
							amount: item.amount + " " + item.currency,
							createTime: dateToString(item.create_time),
							orderStatus: item.order_status,
						}
						this.rdrOrderList.push(orderData);
					})
				}).catch(err => {
					console.log(err);
				})
			},
			getWeekDate() { // 获取最近7天日期
				var weekDate = [];
				for (var i=6; i>=0; i--) {
					weekDate.push(moment().subtract(i,'days').format('MM-DD'));
				}
				return weekDate;
			}
		},
		mounted() {
			setTooltip(this.$store.state.bootstrap); // 设置TIP工具
			this.initializeData(); // 初始化数据
		},
	};
</script>