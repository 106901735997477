<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Ethoca订单列表</h5>
								<p class="mb-0 text-sm">
									主要预警MasterCard拒付，同时可预警其他卡品牌
								</p>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
									<div class="dropdown d-inline">
										<argon-button id="navbarDropdownMenuLink2" color="primary" variant="gradient"
											size="sm" class="dropdown-toggle" data-bs-toggle="dropdown"
											aria-expanded="false">状态筛选</argon-button>
										<ul class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
											aria-labelledby="navbarDropdownMenuLink2" style>
											<li @click="filterStatus(0)">
												<a :class="`dropdown-item border-radius-md ${orderStatus == 0 ? 'bg-white' : ''}`"
													href="javascript:;">待处理</a>
											</li>
											<li @click="filterStatus(1)">
												<a :class="`dropdown-item border-radius-md ${orderStatus == 1 ? 'bg-white' : ''}`"
													href="javascript:;">已处理</a>
											</li>
											<li @click="filterStatus(2)">
												<a :class="`dropdown-item border-radius-md ${orderStatus == 2 ? 'bg-white' : ''}`"
													href="javascript:;">拒绝处理</a>
											</li>
											<li>
												<hr class="horizontal light my-2" />
											</li>
											<li @click="filterStatus('all')">
												<a class="dropdown-item border-radius-md text-danger"
													href="javascript:;">清除筛选</a>
											</li>
										</ul>
									</div>
									<button class="mt-1 mb-0 ms-2 btn btn-outline-primary btn-sm export mt-sm-0"
										data-type="csv" type="button" name="button">

										<span class="btn-inner--icon"><i class="ni ni-archive-2 ni-lg"></i></span> <span
											class="btn-inner--text">导出记录</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="px-0 pb-0 card-body">
						<div class="table-responsive">
							<table id="order-list" class="table table-flush">
								<thead class="thead-light">
									<tr>
										<th>订单号</th>
										<th>卡号</th>
										<th>交易金额</th>
										<th>类型</th>
										<th>预警时间</th>
										<th>状态</th>
										<th>操作</th>
									</tr>
								</thead>
								<tfoot>
									<tr>
										<th>订单号</th>
										<th>卡号</th>
										<th>交易金额</th>
										<th>类型</th>
										<th>预警时间</th>
										<th>状态</th>
										<th>操作</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString
	} from "@/utils/tools.js";
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import {
		DataTable
	} from "simple-datatables";

	export default {
		name: "EthocaOrderList",
		components: {
			ArgonButton,
		},
		data() {
			return {
				dataTable: {},
				orderStatus: "all",
			};
		},
		methods: {
			async getEthocaOrderList(action) {
				this.$kuntnApi.getEthocaOrderList({
					order_status: this.orderStatus,
				}).then((result) => { // 获取Ethoca订单列表
					let orderList = [];
					result.list.forEach((item) => {
						let tableData = {
							'订单号': '<div class="d-flex"><h6 class="my-auto ms-3">' + item
								.order_id +
								'</h6></div>',
							'卡号': '<span class="text-sm">' + item.card_number + '</span>',
							'交易金额': '<span class="text-sm">' + item.amount + ' ' + item.currency +
								'</span>',
							'类型': '<span class="badge badge-sm ' + this.getType(item.type)
								.className +
								'">' + this.getType(item.type).des + '</span>',
							'预警时间': '<span class="text-sm">' + this.getTime(item.create_time) +
								'</span>',
							'状态': '<span class="badge badge-sm ' + this.getStatus(item
									.order_status)
								.className + '">' + this.getStatus(item.order_status).des +
								'</span>',
							'操作': '<span class="text-sm"><a href="/order/ethoca-order-details/' +
								item
								.id +
								'" data-bs-toggle="tooltip" data-bs-original-title="订单详情"><i class="fas fa-eye text-secondary"></i></a></span>',
						};
						orderList.push(tableData);
					});
					if (action == "update") {
						this.dataTable.destroy();
						this.dataTable.init();
					}
					this.dataTable.insert(orderList);
				}).catch(err => {
					console.log(err);
				})
			},
			filterStatus(orderStatus) {
				this.orderStatus = orderStatus;
				this.getEthocaOrderList("update");
			},
			getStatus(orderStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (orderStatus == 0) { // 订单状态 0:待处理 1:已处理 2:拒绝处理
					status_obj.des = "待处理";
					status_obj.className = "badge-dark";
				} else if (orderStatus == 1) {
					status_obj.des = "已处理";
					status_obj.className = "badge-success";
				} else if (orderStatus == 2) {
					status_obj.des = "拒绝处理";
					status_obj.className = "badge-danger";
				}
				return status_obj;
			},
			getType(type) {
				let type_obj = {
					des: "",
					className: ""
				};
				if (type == "fraud") {
					type_obj.des = "欺诈";
					type_obj.className = "badge-warning";
				} else if (type == "dispute") {
					type_obj.des = "争议";
					type_obj.className = "badge-danger";
				}
				return type_obj;
			},
			getTime(time) {
				return dateToString(time);
			},
		},
		mounted() {
			let that = this;

			if (document.getElementById("order-list")) {
				this.dataTable = new DataTable("#order-list", {
					searchable: true,
					fixedHeight: false,
					perPage: 10,
					labels: {
						placeholder: "搜索...",
						perPage: "{select} 每页显示条数",
						noRows: "没有更多记录了",
						info: "显示第{start}到第{end}条记录, 共{rows}条记录",
						noResults: "没有找到符合您搜索的记录",
					}
				});

				this.getEthocaOrderList();

				document.querySelectorAll(".export").forEach(function(el) {
					el.addEventListener("click", function() {
						var type = el.dataset.type;

						var data = {
							type: type,
							filename: "EthocaOrder-" + type,
						};

						if (type === "csv") {
							data.columnDelimiter = "|";
						}

						that.dataTable.export(data);
					});
				});
			}
		},
	};
</script>

<style>
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a,
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a:hover {
		background-image: linear-gradient(310deg, #5f72e4 0%, #7f60e4 100%);
	}
</style>