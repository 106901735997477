import {
	createStore
} from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
	state: {
		isPinned: true,
		isRTL: false,
		color: "",
		sidebarType: "bg-white",
		darkMode: false,
		isNavFixed: false,
		isAbsolute: false,
		showNavs: true,
		showSidenav: true,
		showNavbar: true,
		showFooter: true,
		showMain: true,
		layout: "default", // default:无背景图 custom:有背景图
		bootstrap,
		merchantInfo: {},
	},
	getters: {
		token(state) {
			return state.merchantInfo.token ? state.merchantInfo.token : ''
		},
		merchantId(state) {
			return state.merchantInfo.merchant_id ? state.merchantInfo.merchant_id : ''
		},
	},
	mutations: {
		logout(state) { // 退出登陆
			state.merchantInfo = {};
			window.localStorage.clear();
		},
		signin(state, data) {
			state.merchantInfo = data
			window.localStorage.setItem("merchantInfo",JSON.stringify(data));
		},
		navbarMinimize(state) {
			const sidenav_show = document.querySelector("#app");

			if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains(
					"g-sidenav-hidden")) {
				sidenav_show.classList.add("g-sidenav-pinned");
				sidenav_show.classList.remove("g-sidenav-hidden");
				state.isPinned = true;
			} else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains(
					"g-sidenav-pinned")) {
				sidenav_show.classList.add("g-sidenav-hidden");
				sidenav_show.classList.remove("g-sidenav-pinned");
				state.isPinned = false;
			} else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200) {
				sidenav_show.classList.add("g-sidenav-pinned");
				state.isPinned = true;
			} else {
				sidenav_show.classList.add("g-sidenav-hidden");
				state.isPinned = true;
			}
		},
		setSidebarType(state, payload) {
			state.sidebarType = payload;
		},
		navbarFixed(state) {
			if (state.isNavFixed === false) {
				state.isNavFixed = true;
			} else {
				state.isNavFixed = false;
			}
		},
		toggleDefaultLayout(state) {
			state.showNavbar = !state.showNavbar;
			state.showSidenav = !state.showSidenav;
			state.showFooter = !state.showFooter;
		},
	},
	actions: {
		toggleSidebarColor({
			commit
		}, payload) {
			commit("setSidebarType", payload);
		},
	},
});