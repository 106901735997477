<template>
	<div class="container-fluid mt-6">
		<div class="row mb-5 mt-5">
			<div class="col-lg-3">
				<side-nav />
			</div>
			<div class="col-lg-9 mt-lg-0 mt-4">
				<div id="merchant" class="card card-body mt-4">
					<div class="row align-items-center">
						<div class="col-sm-auto col-4">
							<argon-avatar :img="require('@/assets/images/avatar.png')" alt="商户头像" size="xl" shadow="sm"
								border-radius="lg" />
						</div>
						<div class="col-sm-auto col-8 my-auto">
							<div class="h-100">
								<h5 class="mb-1 font-weight-bolder">{{ merchantInfo.merchant_name }}</h5>
								<p class="mb-0 font-weight-bold text-sm">{{ merchantInfo.email }}</p>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="d-flex align-items-center">
							<div class="text-center w-5">
								<i class="ni ni-money-coins text-lg opacity-6"></i>
							</div>
							<p class="my-auto ms-3">余额</p>
							<p class="text-secondary text-sm ms-auto my-auto me-3">{{ merchantInfo.usd_balance }} USD</p>
							<p class="my-auto ms-3 opacity-10"><button type="button" class="mb-0 btn btn-xs bg-gradient-primary" @click="$router.push({path:'/merchant/recharge'});"><i class="fas fa-plus pe-2" aria-hidden="true"></i> 充值 </button></p>
						</div>
						<hr class="horizontal light" />
					</div>
				</div>
				<div id="reset-password" class="card mt-4">
					<div class="card-header">
						<h5>修改密码</h5>
					</div>
					<div class="card-body pt-0">
						<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
							role="alert">
							<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
							<span class="alert-text">{{ errorTip }}</span>
							<button type="button" class="btn-close py-2" @click="errorTip = ''">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<label class="form-label">当前密码</label>
						<argon-input id="password" type="password" placeholder="当前密码" v-model="password" />
						<label class="form-label">新密码</label>
						<argon-input id="newPassword" type="password" placeholder="新密码" v-model="newPassword" />
						<label class="form-label">确认新密码</label>
						<argon-input id="reNewPassword" type="password" placeholder="确认新密码" v-model="reNewPassword" />
						<h6 class="mt-5">密码要求</h6>
						<p class="text-muted mb-2">
							请按照本指南修改新密码：
						</p>
						<ul class="text-muted ps-4 mb-0 float-start">
							<li>
								<span class="text-sm">至少包含一个特殊字符</span>
							</li>
							<li>
								<span class="text-sm">密码长度不低于6位</span>
							</li>
							<li>
								<span class="text-sm">至少包含一个数字(推荐2个)</span>
							</li>
							<li>
								<span class="text-sm">经常更换密码防止泄漏</span>
							</li>
						</ul>
						<argon-button @click="updatePassword($event)" :disabled="isUpdatePassword"
							class="float-end mt-6 mb-0" color="primary" variant="gradient" size="md"><span
								v-if="isUpdatePassword" class="spinner-border spinner-border-sm" role="status"
								aria-hidden="true"></span> 修改密码</argon-button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="position-fixed bottom-1 end-1 z-index-2">
		<argon-snackbar v-if="isSnackbar" title="密码修改成功" description="即将在2秒后退出登陆，请重新登陆商户平台"
			:icon="{ component: 'ni ni-check-bold', color: 'white' }" color="primary" :close-handler="closeSnackbar" />
	</div>
</template>

<script>
	import ArgonButton from "@/components/ArgonButton.vue";
	import ArgonAvatar from "@/components/ArgonAvatar.vue";
	import ArgonInput from "@/components/ArgonInput.vue";
	import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
	import SideNav from "@/components/Merchant/SideNav.vue";

	export default {
		name: "MerchantCenter",
		components: {
			ArgonButton,
			ArgonAvatar,
			ArgonInput,
			ArgonSnackbar,
			SideNav,
		},
		data() {
			return {
				merchantInfo: {
					merchantName: "加载中...",
					email: "加载中...",
					ethoca_fee: "0.00",
					rdr_fee: "0.00",
				},
				password: "",
				newPassword: "",
				reNewPassword: "",
				errorTip: "",
				isUpdatePassword: false,
				isSnackbar: false,
				isRecharge: false,
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.$kuntnApi.getMerchant({}).then((result) => { // 获取商户信息
					this.merchantInfo = result;
				}).catch(err => {
					console.log(err);
				})
			},
			async updatePassword(event) { // 修改密码
				event.preventDefault(); //防止表单提交
				this.errorTip = ""

				if (this.password.length == 0) {
					this.errorTip = "请输入当前密码"
					return;
				} else if (this.newPassword.length == 0) {
					this.errorTip = "请输入新密码"
					return;
				} else if (this.reNewPassword.length == 0) {
					this.errorTip = "请输入确认新密码"
					return;
				} else if (this.newPassword !== this.reNewPassword) {
					this.errorTip = "确认新密码不一致"
					return;
				}

				this.isUpdatePassword = true
				await this.$kuntnApi.updatePassword({
					old_password: this.password,
					new_password: this.newPassword,
				}).then(() => {
					this.isSnackbar = true;
					setTimeout(() => {
						this.$store.commit('logout');
						this.$router.push({
							path: '/auth/signin'
						});
					}, 2000);
				}).catch(err => {
					this.errorTip = err
					this.isUpdatePassword = false
				})
			},
			closeSnackbar() {
				this.isSnackbar = false;
			},
		},
		mounted() {
			this.initializeData(); // 初始化数据
		},
		beforeMount() {
			this.$store.state.layout = "custom"; // 显示顶部背景图
		},
		beforeUnmount() {
			this.$store.state.layout = "default"; // 不显示顶部背景图
		},
	};
</script>