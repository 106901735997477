<template>
	<nav id="navbarBlur" :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`" v-bind="$attrs" data-scroll="true">
		<div class="px-3 py-1 container-fluid">
			<breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
			<div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none" :class="isRTL ? 'me-3' : ''">
				<a href="#" class="p-0 nav-link text-body" @click.prevent="navbarMinimize">
					<div class="sidenav-toggler-inner">
						<i class="sidenav-toggler-line" :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "></i>
						<i class="sidenav-toggler-line" :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "></i>
						<i class="sidenav-toggler-line" :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "></i>
					</div>
				</a>
			</div>
			<div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
				:class="isRTL ? 'px-0' : 'me-sm-4'">
				<div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
				</div>
				<ul class="navbar-nav justify-content-end">
					<li class="nav-item d-xl-none ps-3 d-flex align-items-center">
						<a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-white"
							@click.prevent="navbarMinimize">
							<div class="sidenav-toggler-inner">
								<i :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"></i>
								<i :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"></i>
								<i :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"></i>
							</div>
						</a>
					</li>
					<li class="nav-item d-flex align-items-center">
						<span @click="logout()" class="px-0 nav-link font-weight-bold cursor-pointer" :class="
						    isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
						  " target="_blank">
							<i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
							<span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
							<span v-else class="d-sm-inline d-none">退出登陆</span>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
	import Breadcrumbs from "./Breadcrumbs.vue";
	import {
		mapState,
		mapMutations
	} from "vuex";

	export default {
		name: "Navbar",
		components: {
			Breadcrumbs
		},
		data() {
			return {
				showMenu: false
			};
		},
		computed: {
			...mapState(["isRTL", "isNavFixed", "darkMode"]),
			currentRouteName() {
				return this.$route.name;
			},
			currentDirectory() {
				let dir = this.$route.path.split("/")[1];
				return dir.charAt(0).toUpperCase() + dir.slice(1);
			}
		},
		created() {
			this.minNav;
		},
		beforeUpdate() {
			this.toggleNavigationOnMobile();
		},
		methods: {
			...mapMutations(["navbarMinimize"]),
			toggleNavigationOnMobile() {
				if (window.innerWidth < 1200) {
					this.navbarMinimize();
				}
			},
			logout() {
				this.$store.commit('logout');
				this.$router.push({
					path: '/auth/signin'
				});
			}
		}
	};
</script>