<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-4 text-center col-lg-8">
          <a
            href="https://www.kuntn.com"
            target="_blank"
            class="mb-2 text-secondary mx-4 mb-sm-0"
          >
            鲲盾官网
          </a>
          <a
            href="https://www.kuntn.com"
            target="_blank"
            class="mb-2 text-secondary mx-4 mb-sm-0"
          >
            关于我们
          </a>
          <a
            href="https://www.kuntn.com"
            target="_blank"
            class="mb-2 text-secondary mx-4 mb-sm-0"
          >
            产品介绍
          </a>
          <a
            href="https://www.kuntn.com"
            target="_blank"
            class="mb-2 text-secondary mx-4 mb-sm-0"
          >
            联系我们
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} by Kuntn.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AuthFooter",
};
</script>
