<template>
	<div class="container-fluid py-5">
		<div class="row">
			<div class="col-12">
				<div class="multisteps-form">
					<div class="row">
						<div class="col-12 col-lg-8 mx-auto mb-4">
							<div class="card">
								<div class="card-body">
									<div class="multisteps-form__progress">
										<button class="multisteps-form__progress-btn" type="button"
											title="输入金额" :class="activeStep >= 0 ? activeClass : ''">
											<span>输入金额</span>
										</button>
										<button class="multisteps-form__progress-btn" type="button" title="扫描付款码"
											:class="activeStep >= 1 ? activeClass : ''">
											扫描付款码
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--form panels-->
					<div class="row">
						<div class="col-12 col-lg-8 m-auto">
							<div class="multisteps-form__form card">
								<!--amount panel-->
								<div class="multisteps-form__panel p-3 border-radius-xl bg-white"
									:class="activeStep === 0 ? activeClass : ''" data-animation="FadeIn">
									<h5 class="font-weight-bolder mb-0 text-center">输入金额</h5>
									<p class="mb-0 text-sm text-center">请输入美元金额</p>
									<div class="multisteps-form__content">
										<div class="row mt-3 mb-3">
											<div class="mt-1 my-auto w-60 m-auto">
												<div v-if="errorTip"
													class="alert alert-danger text-white alert-dismissible show fade py-2"
													role="alert">
													<span class="alert-icon"><i
															class="ni ni-fat-remove ni-lg"></i></span>
													<span class="alert-text">{{ errorTip }}</span>
													<button type="button" class="btn-close py-2" @click="errorTip = ''">
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
											</div>
											<div class="input-group my-auto w-60 m-auto">
												<input type="number" class="form-control" placeholder="充值金额(USD)"
													v-model="amount">
												<argon-button color="primary" variant="gradient" size="md"
													class="border-top-start-radius-0 border-bottom-start-radius-0"
													@click="nextStep()" :disabled="isRecharge"><span v-if="isRecharge"
														class="spinner-border spinner-border-sm" role="status"
														aria-hidden="true"></span> 下一步</argon-button>
											</div>
										</div>
									</div>
								</div>
								<!--qrcode panel-->
								<div class="multisteps-form__panel p-3 border-radius-xl bg-white"
									:class="activeStep === 1 ? activeClass : ''" data-animation="FadeIn">
									<div class="mt-0">
										<div class="text-secondary text-sm my-auto text-center"><img width="15"
												src="@/assets/images/wechat-pay.jpg" /> 使用微信支付将承担手续费，您的实际付款金额为充值金额+手续费
										</div>
										<div id="qrcode" class="mt-3 mx-auto" style="width: 200px;"></div>
									</div>
									<div class="card-body">
										<div class="d-flex align-items-center"></div>
										<div class="d-flex align-items-center">
											<p class="my-auto ms-3">充值金额</p>
											<p class="text-secondary text-sm ms-auto my-auto me-3">{{ rechargeAmount }}
											</p>
										</div>
										<div class="d-flex align-items-center">
											<p class="my-auto ms-3">支付手续费</p>
											<p class="text-secondary text-sm ms-auto my-auto me-3">{{ payFee }}</p>
										</div>
									</div>
									<div class="button-row d-flex mt-2">
										<argon-button type="button" color="light" variant="gradient"
											class="ms-auto mb-0 js-btn-next btn-sm" @click="prevStep()">上一步</argon-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ArgonButton from "@/components/ArgonButton.vue";
	import QRCode from 'qrcodejs2';

	export default {
		name: "Recharge",
		components: {
			ArgonButton,
		},
		data() {
			return {
				activeClass: "js-active position-relative",
				activeStep: 0,
				isRecharge: false,
				errorTip: "",
				amount: "",
				rechargeAmount: "",
				payFee: "",
				rechargeTimer: "",
			};
		},
		methods: {
			nextStep() {
				if (!/^(-)?(0|[1-9]\d*)(\.\d{1,2})?$/.test(this.amount)) {
					this.errorTip = "请输入正确的金额";
					return;
				} else if (this.amount < 1) {
					this.errorTip = "金额不能低于1";
					return;
				}

				this.isRecharge = true;
				this.$kuntnApi.recharge({ // 充值
					amount: this.amount,
					currency: 'USD',
				}).then((result) => {
					this.rechargeAmount = result.pay_amount + ' ' + result.pay_currency;
					this.payFee = result.pay_fee + ' ' + result.pay_currency;
					new QRCode("qrcode", {
						width: 200,
						height: 200,
						text: result.pay_url,
					});
					
					this.activeStep = 1;
					this.isRecharge = false;
					
					this.timerGetRechargeOrder(result.id);
				}).catch(err => {
					console.log(err)
				});
			},
			prevStep() {
				clearInterval(this.rechargeTimer);
				var qrcodeObj = document.getElementById("qrcode");
				qrcodeObj.innerHTML = "";
				
				if (this.activeStep > 0) {
					this.activeStep -= 1;
				}
			},
			async timerGetRechargeOrder(id) {
				clearInterval(this.rechargeTimer);
				this.rechargeTimer = setInterval(() => {
					this.$kuntnApi.getRechargeOrder({ // 获取充值订单详情
						id: id,
					}).then((result) => {
						if (result.recharge_status == 1) {
							this.$router.push({
								path: '/merchant/merchant-center'
							});
						}
					}).catch(err => {
						console.log(err);
					})
				}, 6000);
			},
		},
	};
</script>

<style>
	.multisteps-form__progress-btn.js-active, .multisteps-form__progress-btn.js-active span{
		color: #FFF !important;
	}
	.multisteps-form__progress-btn {
		color: #6d8399 !important;
	}
</style>