<template>
	<nav class="navbar navbar-expand-lg top-0 z-index-3 position-absolute mt-4"
		:class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'">
		<div class="container ps-2 pe-0">
			<router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
				:class="darkMode ? 'text-black' : 'text-white'" to="/"><img style="width: 100px; margin-top: -20px" src="@/assets/images/nav-logo.png" class="position-absolute" />
			</router-link>
		</div>
	</nav>
</template>

<script>
	export default {
		name: "AuthNavbar",
		props: {
			isBlur: {
				type: String,
				default: ""
			},
			darkMode: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
			};
		},
		computed: {
		}
	};
</script>