<template>
	<div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
	<sidenav v-if="showSidenav" />
	<main class="main-content position-relative max-height-vh-100 h-100">
		<navbar v-if="showNavbar" />
		<router-view />
		<main-footer v-show="showFooter" />
	</main>
</template>
<script>
	import Sidenav from "@/components/Main/Sidenav";
	import Navbar from "@/components/Main/Navbar.vue";
	import MainFooter from "@/components/Main/Footer.vue";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		activateDarkMode
	} from "@/assets/js/dark-mode";

	export default {
		name: "App",
		components: {
			Sidenav,
			Navbar,
			MainFooter,
		},
		computed: {
			...mapState([
				"layout",
				"showSidenav",
				"showNavbar",
				"showFooter",
			])
		},
		methods: {
			...mapMutations([
				"setSidebarType"
			]),
		},
		created() {
			// 设置为黑暗模式
			this.$store.state.darkMode = true;
			this.setSidebarType("bg-default");
			activateDarkMode();
		},
	};
</script>