<template>
	<div class="card shadow-lg mx-4 card-profile-bottom">
		<div class="card-body p-3">
			<div class="row justify-content-between">
				<div class="col-auto my-auto">
					<div class="h-100">
						<h5 class="mb-1">{{ merchantName }}</h5>
						<p class="mb-0 font-weight-bold text-sm">{{ email }}</p>
					</div>
				</div>
				<div class="col-auto">
					<argon-button color="primary" class="mt-auto" @click="$router.push({path:'/account/add-account'});">+ 添加商家</argon-button>
				</div>
			</div>
		</div>
	</div>
	<div class="py-4 container-fluid">
		<section class="py-3">
			<div class="row">
				<div class="text-left col-md-8 me-auto">
					<h5>拒付商家管理</h5>
					<p>
						您需要在此页面添加商家并通过审核后，才能开启针对此商家的拒付预警监控
					</p>
				</div>
			</div>
			<div class="mt-2 row mt-lg-4">
				<div v-for="item in accountList" :key="item.id" class="mb-4 col-lg-4 col-md-6">
					<account-list-card :accountId="item.id" :companyName="item.company_name" :ethocaStatus="item.ethoca_status" :rdrStatus="item.rdr_status" :createTime="item.create_time" :dropdown="[
				{
					label: '编辑商家',
					route: '/account/edit-account/' + item.id,
				},
				{
					label: 'Ethoca描述符',
					route: '/account/ethoca-descriptor-list/' + item.id,
				},
				{
					label: 'VISA RDR描述符',
					route: '/account/rdr-descriptor-list/' + item.id,
				},
            ]" />
				</div>

				<div class="mb-4 col-lg-4 col-md-6">
					<add-card :title="{ text: '添加商家', variant: 'h5' }" :to="{ name: '添加商家' }" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import AccountListCard from "@/components/Cards/AccountListCard.vue"; // 账户列表卡片
	import AddCard from "@/components/Cards/AddCard.vue"; // 添加卡片
	import setTooltip from "@/assets/js/tooltip.js"; // TIP工具

	export default {
		name: "AccountList",
		components: {
			ArgonButton,
			AccountListCard,
			AddCard,
		},
		data() {
			return {
				merchantName: "加载中...",
				email: "加载中...",
				accountList: [],
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.$kuntnApi.getMerchant({}).then((result) => { // 获取商户信息
					this.merchantName = result.merchant_name;
					this.email = result.email;
				}).catch(err => {
					console.log(err);
				})

				this.$kuntnApi.getAccountList({ // 获取商家列表
					page: 1,
					size: 50,
				}).then((result) => {
					this.accountList = result.list;
				}).catch(err => {
					console.log(err);
				})
			},
		},
		mounted() {
			setTooltip(this.$store.state.bootstrap); // 设置TIP工具
			this.initializeData(); // 初始化数据
		},
		beforeMount() {
			this.$store.state.layout = "custom"; // 显示顶部背景图
		},
		beforeUnmount() {
			this.$store.state.layout = "default"; // 不显示顶部背景图
		},
	};
</script>