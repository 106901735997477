<template>
	<div class="card h-100">
		<div class="card-header">
			<h5 class="mb-0 text-capitalize">{{ title }}</h5>
		</div>
		<div class="card-body pt-0">
			<div v-if="members.length == 0" class="mt-5">
				<h4 class="text-body opacity-9 text-center">暂无拒付商家</h4>
				<p class="text-center text-sm">拒付预警未启用，请先添加商家</p>
				<div class="col-12 my-auto text-center">
					<argon-button color="primary" variant="gradient" class="mb-0" @click="$router.push({path:'/account/add-account'});">添加拒付商家</argon-button>
				</div>
			</div>
			<ul v-else class="list-group list-group-flush">
				<li v-for="({info: { name, link }, status: { ethocaStatus, rdrStatus } }, index) of members" :key="index" class="list-group-item px-0">
					<div class="row align-items-center">
						<div class="col ml-2">
							<h6 class="mb-0">
								<a :href="link">{{ name }}</a>
							</h6>
							<span class="badge badge-sm" :class="getEthocaStatus(ethocaStatus).ethocaClassName">{{ getEthocaStatus(ethocaStatus).ethocaStatusDes }}</span>
							<span class="badge badge-sm ms-2" :class="getRDRStatus(rdrStatus).rdrClassName">{{ getRDRStatus(rdrStatus).rdrStatusDes }}</span>
						</div>
						<div class="col-auto d-flex align-items-center">
							<div class="avatar-group">
								<a href="javascript:;" class="avatar avatar-xs rounded-circle m-2" data-bs-toggle="tooltip" title="Ethoca">
									<img :src="require(`@/assets/images/ethoca${ethocaStatus !== 1 ? '-dark' : ''}.jpg`)" />
								</a>
								<a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" title="VISA快速争议处理">
									<img :src="require(`@/assets/images/rdr${rdrStatus !== 1 ? '-dark' : ''}.jpg`)" />
								</a>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	
	export default {
		name: "AccountCard",
		components: {
			ArgonButton,
		},
		props: {
			title: {
				type: String,
				default: "商家列表",
			},
			members: {
				type: Array,
				required: true,
				info: {
					name: String,
					link: String,
				},
				status: {
					type: Object,
					ethocaStatus: Number,
				},
			},
		},
		methods: {
			getEthocaStatus(ethocaStatus) {
				let ethocaStatusDes = "";
				let ethocaClassName = "";
				if (ethocaStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					ethocaStatusDes = "Ethoca未开启";
					ethocaClassName = "badge-secondary";
				} else if (ethocaStatus == 1) {
					ethocaStatusDes = "Ethoca预警中";
					ethocaClassName = "badge-success";
				} else if (ethocaStatus == 2) {
					ethocaStatusDes = "Ethoca审核中";
					ethocaClassName = "badge-warning";
				} else if (ethocaStatus == 3) {
					ethocaStatusDes = "Ethoca审核失败";
					ethocaClassName = "badge-danger";
				} else if (ethocaStatus == 4) {
					ethocaStatusDes = "Ethoca关闭中";
					ethocaClassName = "badge-secondary";
				}
				
				return {
					ethocaStatusDes,
					ethocaClassName,
				};
			},
			getRDRStatus(rdrStatus) {
				let rdrStatusDes = "";
				let rdrClassName = "";
				if (rdrStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					rdrStatusDes = "快速争议处理未开启";
					rdrClassName = "badge-secondary";
				} else if (rdrStatus == 1) {
					rdrStatusDes = "快速争议处理预警中";
					rdrClassName = "badge-success";
				} else if (rdrStatus == 2) {
					rdrStatusDes = "快速争议处理审核中";
					rdrClassName = "badge-warning";
				} else if (rdrStatus == 3) {
					rdrStatusDes = "快速争议处理审核失败";
					rdrClassName = "badge-danger";
				} else if (rdrStatus == 4) {
					rdrStatusDes = "快速争议处理关闭中";
					rdrClassName = "badge-secondary";
				}
				
				return {
					rdrStatusDes,
					rdrClassName,
				};
			},
		},
	};
</script>