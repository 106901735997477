export function dateToString(timestampInSeconds) {
	if (!timestampInSeconds) {
		return "";
	}
	const timestampInMilliseconds = timestampInSeconds * 1000; // 将秒转换为毫秒
	const date = new Date(timestampInMilliseconds); // 创建Date对象，传入时间戳
	const dateString = date.toLocaleString(); // 使用 toLocaleString() 方法将日期转换为本地时间字符串
	return dateString;
}

/*
  计算订单剩余时间
  @time1(number): 当前时间，单位s
  @time2(number): 需计算时间，单位s
  @timeoutTime(number): 超时时间，单位s
  @return string: 表示过去了多少分、秒、小时、天 
*/
export function formateOrderSurplusDate(time1, time2, timeoutTime) {
	let day = '';
	let hour = '';
	let minute = '';
	let seconds = '';
	let time = time1 - time2;

	if (time >= timeoutTime) {
		return "该订单已超过24小时";
	} else {
		if (time > 60) { // 大于60s,即变成分钟+秒，
			minute = Math.floor(time / 60);
			seconds = Math.floor(time % 60);
			if (minute > 60) { // 大于60min，即变成小时+分钟... 由于255min
				hour = Math.floor(minute / 60);
				minute = Math.floor(minute % 60);
			}
			if (hour > 24) { // 大于24h,即变成天+小时...
				day = Math.floor(hour / 24);
				hour = Math.floor(hour % 24);
			}
		} else {
			seconds = time;
		}
	}

	let result = "";
	if (seconds > 0) {
		result = `${seconds}秒`;
	}
	if (minute > 0) {
		result = `${minute}分钟` + result;
	}
	if (hour > 0) {
		result = `${hour}小时` + result;
	}
	if (day > 0) {
		result = `${day}天` + result;
	}
	return "本订单已产生" + result + " (请在24小时内处理该订单)";
}

/*
  计算到期时间
  @time1(number): 当前时间，单位s
  @time2(number): 需计算时间，单位s
  @return string: 到期时间
*/
export function formateDueDate(time1, time2) {
	let time = time1 + time2;
	return dateToString(time);
}
