<template>
	<div class="container-fluid py-4">
		<div class="row">
			<div class="col-lg-12 mx-auto">
				<div class="card my-5">
					<div class="card-header p-3 pb-0">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<h6>订单详情</h6>
								<p class="text-sm mb-0">
									订单号 <b>{{ orderDetails.order_id }}</b>
								</p>
								<p class="text-sm">
									预警时间
									<b>{{ getTime(orderDetails.create_time) }}</b>
								</p>
							</div>
							<argon-button @click="$router.back()" color="secondary" variant="gradient"
								class="ms-auto mb-0">返回</argon-button>
						</div>
					</div>
					<div class="card-body p-3 pt-0">
						<hr class="horizontal light mt-0 mb-4" />
						<div class="row">
							<div class="col-lg-6 col-md-6 col-12">
								<div class="d-flex">
									<div>
										<argon-avatar :img="require('@/assets/images/rdr-lg.jpg')" class="me-3"
											size="lg" border-radius="lg" alt="product image" />
									</div>
									<div>
										<h6 class="text-lg mb-0 mt-2">{{ getType(orderDetails.type) }}</h6>
										<p class="text-sm mb-3 text-bold">
											{{ orderDetails.order_status !== 0 ? "该订单已处理":getSurplusTime(orderDetails.server_time, orderDetails.create_time)}}
										</p>
										<argon-badge :color="getStatus(orderDetails.order_status).color"
											variant="gradient"
											size="md">{{ getStatus(orderDetails.order_status).des }}</argon-badge>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6 col-12 my-auto text-end">
								<argon-button v-if="orderDetails.order_status == 1" color="primary" variant="gradient" class="mb-0">已触发自动退款</argon-button>
								<argon-button v-else color="light" variant="gradient" class="mb-0">未触发自动退款</argon-button>
								<p class="text-sm mt-2 mb-0 text-danger">
									{{ orderDetails.order_status == 1 ? '该拒付预警已触发自动退款规则，请前往商家后台查看此订单的交易和退款状态' : '该拒付预警未触发自动退款规则，请自行处理' }}
								</p>
							</div>
						</div>
						<hr class="horizontal light mt-4 mb-4" />
						<div class="row">
							<div class="col-lg-6 col-md-6 col-12">
								<h6 class="mb-3">信用卡卡号后4位</h6>
								<div
									class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
									<img class="w-10 me-3 mb-0" src="@/assets/images/visa.png" alt="logo" />
									<h6 class="mb-0">
										**** **** **** {{ orderDetails.card_number }}
									</h6>
								</div>
								<h6 class="mb-3 mt-4">付款卡信息</h6>
								<ul class="list-group mb-4">
									<li class="bg-dark border-0 d-flex p-4 mb-2 border-radius-lg">
										<div class="d-flex flex-column">
											<span class="mb-2 text-xs">
												授权码:
												<span
													class="text-light ms-2 font-weight-bold">{{ orderDetails.auth_code }}</span>
											</span>
											<span class="mb-2 text-xs">
												银行商户号:
												<span
													class="text-light font-weight-bold ms-2">{{ orderDetails.caid }}</span>
											</span>
											<span class="mb-2 text-xs">
												收单银行Bin:
												<span
													class="text-light font-weight-bold ms-2">{{ orderDetails.card_bin }}</span>
											</span>
											<span class="text-xs">
												收单行参考号ARN:
												<span
													class="text-light ms-2 font-weight-bold">{{ orderDetails.arn }}</span>
											</span>
										</div>
									</li>
								</ul>
								<h6 class="mb-3">商家信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">Verify商家ID:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.rdr_merchant_id }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家姓名:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.merchant_name }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">描述名称:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.merchant_descriptor }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">描述联系人:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.merchant_descriptor_contact }}</span>
								</div>
								<div class="mb-4 d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家类别代码:</span>
									<span class="col-6 text-light text-end font-weight-bold">{{ orderDetails.merchant_category_code }}</span>
								</div>
							</div>
							<div class="col-lg-6 col-12 ms-auto">
								<h6 class="mb-3">交易信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易金额:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.amount }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易币种:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.currency }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家订单ID:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.transaction_id }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">付款类型:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.payment_type }}</span>
								</div>
								
								<div class="mb-4 d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易的授权时间:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ getTime(orderDetails.transaction_time) }}</span>
								</div>
								<h6 class="mb-3">预警信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警来源:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.source }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付金额:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.chargeback_amount }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付币种:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.chargeback_currency }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付原因代码:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.chargeback_reason_code }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">状态码:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.status_code }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警创建时间:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ getTime(orderDetails.create_time) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString,
		formateOrderSurplusDate
	} from '@/utils/tools.js';
	import Choices from "choices.js";
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import ArgonAvatar from "@/components/ArgonAvatar.vue"; // 头像
	import ArgonBadge from "@/components/ArgonBadge.vue"; // 标签

	export default {
		name: "RDROrderDetails",
		components: {
			ArgonButton,
			ArgonAvatar,
			ArgonBadge,
		},
		data() {
			return {
				orderDetails: [],
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.$kuntnApi.getRDROrder({ // 获取RDR订单详情
					id: this.$route.params.id,
				}).then((result) => {
					this.orderDetails = result;

					// 下拉框数据
					let choicesData = [];
					if (result.type === 'fraud') {
						choicesData = this.choicesFraud;
					} else if (result.type === 'dispute') {
						choicesData = this.choicesDispute;
					}

					if (document.getElementById("feedbackOutcome")) { // 下拉框
						var element = document.getElementById("feedbackOutcome");
						return new Choices(element, {
							allowHTML: true,
							searchEnabled: false,
							itemSelectText: "点击选择",
							shouldSort: false, // false:按给定数据排序
							classNames: {
								containerOuter: "choices text-dark",
								itemChoice: "choices__item--choice text-grey",
							},
							choices: choicesData,
						});
					}
				}).catch(err => {
					console.log(err);
				})
			},
			getStatus(orderStatus) {
				let status_obj = {
					des: "",
					color: ""
				};
				if (orderStatus == 0) { // 订单状态 0:待处理 1:已处理 2:拒绝处理
					status_obj.des = "待处理";
					status_obj.color = "dark";
				} else if (orderStatus == 1) {
					status_obj.des = "已处理";
					status_obj.color = "success";
				} else if (orderStatus == 2) {
					status_obj.des = "拒绝处理";
					status_obj.color = "danger";
				}
				return status_obj;
			},
			getType(type) {
				let type_desc = "";
				if (type == "fraud") {
					type_desc = "欺诈订单";
				} else if (type == "dispute") {
					type_desc = "争议订单";
				}
				return type_desc;
			},
			getOutcome(type,outcome) {
				let status_desc = "未知";
				
				if (type == "fraud") {
					switch (outcome) {
						case "stopped":
							status_desc = "通过退款/稍后退款阻止争议";
							break;
						case "partially_stopped":
							status_desc = "通过预警停止部分交易";
							break;
						case "previously_cancelled":
							status_desc = "收到预警前已发起退款";
							break;
						case "missed":
							status_desc = "预警过晚，已经被拒付";
							break;
						case "notfound":
							status_desc = "预警交易无法在商户系统找到";
							break;
						case "account_suspended":
							status_desc = "持卡人账户被冻结";
							break;
						case "in_progress":
							status_desc = "正在处理该预警";
							break;
						case "shipper_contacted":
							status_desc = "需要进一步联系物流公司其并截止物流处理";
							break;
						case "other":
							status_desc = "其他处理结果";
							break;
					}
				} else if (type == "dispute") {
					switch (outcome) {
						case "resolved":
							status_desc = "通过退款/与客户联系阻止争议";
							break;
						case "previously_refunded":
							status_desc = "收到预警前已发起退款";
							break;
						case "unresolved_dispute":
							status_desc = "预警过晚，已经被拒付";
							break;
						case "notfound":
							status_desc = "该预警无法在商户系统找到对应交易";
							break;
						case "other":
							status_desc = "其他处理结果";
							break;
					}
				}
				return status_desc;
			},
			getRefundedStatus(status) {
				let status_desc = "";
				if (status == 0) {
					status_desc = "待反馈";
				} else if (status == 1) {
					status_desc = "已退款";
				} else if (status == 2) {
					status_desc = "未退款";
				} else if (status == 3) {
					status_desc = "退款处理中/稍后退款";
				}
				return status_desc;
			},
			getTime(time) {
				return dateToString(time);
			},
			getSurplusTime(time1, time2) {
				return formateOrderSurplusDate(time1, time2, 86400);
			}
		},
		mounted() {
			this.initializeData(); // 初始化数据
		}
	};
</script>

<style>
	.text-grey {
		color: #e5e5e5;
	}

	.is-highlighted {
		background: #fff !important;
		color: #000 !important;
	}
</style>