import axios from 'axios'
import {
	sha256
} from 'js-sha256'
import store from '@/store/index.js'
import router from '@/router/index.js'
const http = axios.create({
	timeout: 1000 * 30,
	withCredentials: false,
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json"
	}
})
const apiUrl = 'https://api.kuntn.com'
const secretKey = "SK-WN17VSB3NZHTNVX-NCGF9FLUE9X2WLH"

// 请求拦截器
http.interceptors.request.use(config => {
	config.headers.Signature = sign(config.data)
	return config;
}, error => Promise.reject(error))

// 响应拦截器
http.interceptors.response.use(response => {
	if (response.data.code == 1012 || response.data.code == 1013) { // 1012:令牌过期 1013:令牌错误
		store.commit('logout');
		router.push({
			path: '/auth/signin'
		});
		return;
	}
	return response;
}, function(error) {
	// if (error.response) {
	//   // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
	//   if (error.message == 'Network Error') {
	//     msg("网络异常，请检查网络是否连接!!", "error");
	//   } else {
	//     msg("系统开小差啦！msg：" + error.message, "error");
	//   }
	// } else if (error.request) {
	//   // 请求已经成功发起，但没有收到响应
	//   msg("请求超时或系统无响应！msg：" + error.message, "error");
	// } else {
	//   // 发送请求时出了点问题
	//   msg('请求出现了点问题，请重新尝试!msg:' + error.message, "error");
	// }
	return Promise.reject(error)
})


function post({
	url,
	params = {},
	isToken = false
}) {
	const timestamp = Math.round(new Date().getTime() / 1000).toString();
	let extraParams = {
		timestamp: timestamp,
	}
	if (isToken) {
		if (store.getters.merchantId) {
			extraParams.merchant_id = store.getters.merchantId;
		}
		if (store.getters.token) {
			extraParams.token = store.getters.token;
		}
	}
	
	for (var key in params) { // 删除空对象
		if (params[key] === "") {
			delete params[key];
		}
	}
	let obj = Object.assign(params, extraParams)
	return new Promise((resolve, reject) => {
		http.post(apiUrl + url, obj).then(res => {
			let data = res.data;
			if (data.code == 200) {
				resolve(data);
			} else {
				reject(data.message);
			}
		}).catch(e => {
			reject(e.message);
		})
	})
}

function sign(params = {}) {
	let signsort = Object.keys(params).sort();
	let signkeys = "";
	signsort.forEach((key) => {
		if (params[key] || params[key] === 0) {
			signkeys = signkeys + key + "=" + params[key] + "&";
		}
	})
	let signstr = signkeys + "secret_key=" + secretKey;
	let sign = sha256(signstr).toUpperCase();
	return sign;
}

export default {
	signup(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Merchant/Signup",
				params: {
					email: data.email,
					merchant_name: data.merchant_name,
					password: data.password,
					verify_code: data.verify_code,
				}
			}).then((result) => {
				store.commit('signin', result.result);
				router.push({
					path: '/index'
				});
			}).catch(err => {
				reject(err);
			});
		})
	},
	signin(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Merchant/Signin",
				params: {
					email: data.email,
					verify: data.verify,
					verify_type: data.verify_type,
				}
			}).then((result) => {
				store.commit('signin', result.result);
				router.push({
					path: '/index'
				});
			}).catch(err => {
				reject(err);
			});
		})
	},
	sendCode(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Common/SendCode",
				params: {
					email: data.email,
				}
			}).then(() => {
				resolve(true);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getMerchant() {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Merchant/GetMerchant",
				params: {},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	updatePassword(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Merchant/UpdatePassword",
				params: {
					old_password: data.old_password,
					new_password: data.new_password,
				},
				isToken: true,
			}).then(() => {
				resolve(true);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getAccount(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/GetAccount",
				params: {
					account_id: data.account_id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getAccountList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/GetAccountList",
				params: {
					page: data.page,
					size: data.size,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	addAccount(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/AddAccount",
				params: {
					company_name: data.company_name,
					ethoca_descriptor: data.ethoca_descriptor,
					ethoca_match_model: data.ethoca_match_model,
					is_ethoca_open: data.is_ethoca_open,
					rdr_acquirer_name: data.rdr_acquirer_name,
					rdr_acquirer_bin: data.rdr_acquirer_bin,
					rdr_caid: data.rdr_caid,
					rdr_descriptor: data.rdr_descriptor,
					rdr_match_model: data.rdr_match_model,
					is_rdr_open: data.is_rdr_open,
				},
				isToken: true,
			}).then(() => {
				resolve(true);
			}).catch(err => {
				reject(err);
			});
		})
	},
	updateAccount(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/UpdateAccount",
				params: {
					account_id: data.account_id,
					ethoca_descriptor: data.ethoca_descriptor,
					ethoca_match_model: data.ethoca_match_model,
					is_ethoca_open: data.is_ethoca_open,
					rdr_acquirer_name: data.rdr_acquirer_name,
					rdr_acquirer_bin: data.rdr_acquirer_bin,
					rdr_caid: data.rdr_caid,
					rdr_descriptor: data.rdr_descriptor,
					rdr_match_model: data.rdr_match_model,
					is_rdr_open: data.is_rdr_open,
				},
				isToken: true,
			}).then(() => {
				resolve(true);
			}).catch(err => {
				reject(err);
			});
		})
	},
	addDescriptor(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/AddDescriptor",
				params: {
					account_id: data.account_id,
					descriptor: data.descriptor,
					match_model: data.match_model,
					type: data.type,
				},
				isToken: true,
			}).then(() => {
				resolve(true);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getDescriptorList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Account/GetDescriptorList",
				params: {
					account_id: data.account_id,
					page: data.page,
					size: data.size,
					type: data.type,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getEthocaOrderList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/GetEthocaOrderList",
				params: {
					page: data.page,
					size: data.size,
					order_status: data.order_status,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getEthocaOrder(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/GetEthocaOrder",
				params: {
					id: data.id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	feedbackEthocaOrder(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/FeedbackEthocaOrder",
				params: {
					id: data.id,
					feedback_outcome: data.feedback_outcome,
					feedback_refunded_status: data.feedback_refunded_status,
					feedback_amount_stopped: data.feedback_amount_stopped,
					feedback_merchant_order_id: data.feedback_merchant_order_id,
					feedback_first_party_fraud: data.feedback_first_party_fraud,
					feedback_comments: data.feedback_comments,
					feedback_time: data.feedback_time,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getRDROrderList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/GetRDROrderList",
				params: {
					page: data.page,
					size: data.size,
					order_status: data.order_status,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getRDROrder(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/GetRDROrder",
				params: {
					id: data.id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getChargeback24h() {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Stat/GetChargeback24h",
				params: {},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getChargebackVolume() {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Stat/GetChargebackVolume",
				params: {},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getChargebackChart() {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Stat/GetChargebackChart",
				params: {},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getBillList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Bill/GetBillList",
				params: {
					page: data.page,
					size: data.size,
					bill_status: data.bill_status,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getBill(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Bill/GetBill",
				params: {
					id: data.id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	payBill(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Bill/PayBill",
				params: {
					id: data.id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getRechargeOrder(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/getRechargeOrder",
				params: {
					id: data.id,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getRechargeOrderList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Order/GetRechargeOrderList",
				params: {
					page: data.page,
					size: data.size,
					recharge_status: data.recharge_status,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	recharge(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Wallet/Recharge",
				params: {
					amount: data.amount,
					currency: data.currency,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
	getAssetLogList(data) {
		return new Promise((resolve, reject) => {
			post({
				url: "/Sys/Wallet/getAssetLogList",
				params: {
					page: data.page,
					size: data.size,
				},
				isToken: true,
			}).then((result) => {
				resolve(result.result);
			}).catch(err => {
				reject(err);
			});
		})
	},
}