<template>
	<div class="mt-4 card h-100 mt-md-0">
		<div class="p-3 pb-0 card-header">
			<div class="d-flex align-items-center">
				<h6>{{ title }}</h6>
				<button type="button"
					class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
					data-bs-toggle="tooltip" data-bs-placement="bottom" :title="tooltip">
					<i class="fas fa-info"></i>
				</button>
			</div>
		</div>
		<div class="px-3 pt-0 pb-2 card-body">
			<div class="p-0 table-responsive">
				<table class="table mb-0 align-items-center justify-content-center">
					<thead>
						<tr>
							<th v-for="(header, index) of headers" :key="index"
								class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
								{{ header }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="({ orderId, amount, createTime, orderStatus }, index) of list" :key="index">
							<td>
								<p class="mb-0 text-sm font-weight-bold opacity-10">
									{{ orderId }}
								</p>
							</td>
							<td>
								<p class="mb-0 text-sm font-weight-bold">{{ amount }}</p>
							</td>
							<td>
								<p class="mb-0 text-sm font-weight-bold opacity-10"><span class="badge badge-sm" :class="getStatus(orderStatus).className">{{ getStatus(orderStatus).des }}</span></p>
							</td>
							<td>
								<p class="mb-0 text-sm font-weight-bold">{{ createTime }}</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "OrderMinList",
		props: {
			title: {
				type: String,
				default: "",
			},
			tooltip: {
				type: String,
				default: "",
			},
			headers: {
				type: Array,
				required: true,
			},
			list: {
				type: Array,
				required: true,
				orderId: String,
				amount: String,
				createTime: String,
				orderStatus: Number,
			},
		},
		methods: {
			getStatus(orderStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (orderStatus == 0) { // 订单状态 0:待处理 1:已处理 2:拒绝处理
					status_obj.des = "待处理";
					status_obj.className = "badge-dark";
				} else if (orderStatus == 1) {
					status_obj.des = "已处理";
					status_obj.className = "badge-success";
				} else if (orderStatus == 2) {
					status_obj.des = "拒绝处理";
					status_obj.className = "badge-danger";
				}
				return status_obj;
			},
		}
	};
</script>