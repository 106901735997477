<template>
	<div class="container-fluid py-4">
		<div class="row">
			<div class="col-lg-12 mx-auto">
				<div class="card my-5">
					<div class="card-header p-3 pb-0">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<h6>订单详情</h6>
								<p class="text-sm mb-0">
									订单号 <b>{{ orderDetails.order_id }}</b>
								</p>
								<p class="text-sm mb-0">
									EthocaID <b>{{ orderDetails.ethoca_id }}</b>
								</p>
								<p class="text-sm">
									预警时间
									<b>{{ getTime(orderDetails.create_time) }}</b>
								</p>
							</div>
							<argon-button @click="$router.back()" color="secondary" variant="gradient"
								class="ms-auto mb-0">返回</argon-button>
						</div>
					</div>
					<div class="card-body p-3 pt-0">
						<hr class="horizontal light mt-0 mb-4" />
						<div class="row">
							<div class="col-lg-6 col-md-6 col-12">
								<div class="d-flex">
									<div>
										<argon-avatar :img="require('@/assets/images/ethoca-lg.jpg')" class="me-3"
											size="lg" border-radius="lg" alt="product image" />
									</div>
									<div>
										<h6 class="text-lg mb-0 mt-2">{{ getType(orderDetails.type) }}</h6>
										<p class="text-sm mb-3 text-bold">
											{{ orderDetails.order_status !== 0 ? "该订单已处理":getSurplusTime(orderDetails.server_time, orderDetails.create_time)}}
										</p>
										<argon-badge :color="getStatus(orderDetails.order_status).color"
											variant="gradient"
											size="md">{{ getStatus(orderDetails.order_status).des }}</argon-badge>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6 col-12 my-auto text-end">
								<argon-button v-if="orderDetails.order_status == 0" color="primary" variant="gradient" class="mb-0" data-bs-toggle="modal"
									data-bs-target="#feedback">反馈处理结果</argon-button>
								<argon-button v-else color="light" variant="gradient" class="mb-0">已反馈</argon-button>

								<p class="text-sm mt-2 mb-0 text-danger">
									{{ orderDetails.order_status == 0 && (orderDetails.server_time - orderDetails.create_time >= 86400) ? '该拒付信息已超过24小时未处理，您仍然可以处理（退款或联系持卡人）并在此反馈处理结果，但此拒付信息仍然可能会发送到收单方' : '您已处理该拒付，请确保您的处理结果真实且有效，如果您的处理结果与反馈结果不符，则该拒付可能会发送到收单行' }}
								</p>
							</div>
						</div>
						<hr class="horizontal light mt-4 mb-4" />
						<div class="row">
							<div class="col-lg-4 col-md-6 col-12">
								<h6 class="mb-3">信用卡卡号</h6>
								<div
									class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
									<img class="w-10 me-3 mb-0" src="@/assets/images/mastercard.png" alt="logo" />
									<h6 class="mb-0">
										{{ orderDetails.card_number }}
									</h6>
								</div>
								<h6 class="mb-3 mt-4">付款卡信息</h6>
								<ul class="list-group mb-4">
									<li class="bg-dark border-0 d-flex p-4 mb-2 border-radius-lg">
										<div class="d-flex flex-column">
											<span class="mb-2 text-xs">
												发卡行:
												<span
													class="text-light ms-2 font-weight-bold">{{ orderDetails.issuer }}</span>
											</span>
											<span class="mb-2 text-xs">
												卡Bin前8位:
												<span
													class="text-light font-weight-bold ms-2">{{ orderDetails.card_bin }}</span>
											</span>
											<span class="mb-2 text-xs">
												授权码:
												<span
													class="text-light ms-2 font-weight-bold">{{ orderDetails.auth_code }}</span>
											</span>
											<span class="text-xs">
												收单行参考号ARN:
												<span
													class="text-light ms-2 font-weight-bold">{{ orderDetails.arn }}</span>
											</span>
										</div>
									</li>
								</ul>
								<h6 class="mb-3">商家信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">Ethoca商家ID:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.ethoca_merchant_id }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家姓名:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.merchant_name }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">相关描述:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.merchant_descriptor }}</span>
								</div>
								<div class="mb-4 d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家类别代码:</span>
									<span class="col-6 text-light text-end font-weight-bold">{{ orderDetails.merchant_category_code }}</span>
								</div>
							</div>
							<div class="col-lg-4 col-12 ms-auto">
								<h6 class="mb-3">交易信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易金额:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.amount }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易币种:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.currency }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易类型:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.transaction_type }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易由谁确认:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.initiated_by }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">是否是3Ds交易责任方:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.liability }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">卡组织分配的交易ID:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.transaction_id }}</span>
								</div>
								<div class="mb-4 d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">交易的授权时间:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ getTime(orderDetails.transaction_time) }}</span>
								</div>
								<h6 class="mb-3">预警信息</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警来源:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.source }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付金额:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.chargeback_amount }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付币种:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.chargeback_currency }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">拒付原因代码:</span>
									<span
										class="col-6 text-light text-sm font-weight-bold text-end">{{ orderDetails.chargeback_reason_code }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警时间间隔(小时):</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ orderDetails.warning_interval }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警创建时间:</span>
									<span
										class="col-6 text-light text-sm text-end font-weight-bold">{{ getTime(orderDetails.create_time) }}</span>
								</div>
							</div>
							<div class="col-lg-4 col-12 ms-auto">
								<h6 class="mb-3">商用处理及反馈结果</h6>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">退款金额:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.feedback_amount_stopped }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">商家匹配订单流水号:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.feedback_merchant_order_id }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">预警处理结果:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ getOutcome(orderDetails.type,orderDetails.feedback_outcome) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">退款状态:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ getRefundedStatus(orderDetails.feedback_refunded_status) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">是否为欺诈:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.feedback_first_party_fraud }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">补充意见:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ orderDetails.feedback_comments }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span class="col-6 mb-2 text-sm">退款及处理时间:</span>
									<span
										class="col-6 text-light font-weight-bold text-end">{{ getTime(orderDetails.feedback_time) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal fade" id="feedback" tabindex="-1" role="dialog" aria-labelledby="feedbackTitle"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content bg-dark">
						<div class="modal-header">
							<h5 class="modal-title">反馈处理结果</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
							</button>
						</div>
						<div class="modal-body">
							<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
								role="alert">
								<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
								<span class="alert-text">{{ errorTip }}</span>
								<button type="button" class="btn-close py-2" @click="errorTip = ''">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form>
								<label for="feedbackOutcome" class="col-form-label"><span class="text-danger">*</span>
									处理结果:</label>
								<select id="feedbackOutcome" class="form-control" name="feedbackOutcome"
									v-model="feedbackOutcome"></select>
								<label for="feedbackRefundedStatus" class="col-form-label"><span
										class="text-danger">*</span> 退款状态:</label>
								<div class="d-flex justify-content-start">
									<div class="form-check">
										<input id="feedbackRefundedStatus1" class="form-check-input" type="radio"
											name="feedbackRefundedStatus" value=1 v-model="feedbackRefundedStatus"
											checked />
										<label class="custom-control-label" for="feedbackRefundedStatus1">
											已退款
										</label>
									</div>
									<div class="form-check ms-3">
										<input id="feedbackRefundedStatus2" class="form-check-input" type="radio"
											name="feedbackRefundedStatus" value=2 v-model="feedbackRefundedStatus" />
										<label class="custom-control-label" for="feedbackRefundedStatus2">
											未退款
										</label>
									</div>
									<div class="form-check ms-3">
										<input id="custom-control-label3" class="form-check-input" type="radio"
											name="feedbackRefundedStatus" value=3 v-model="feedbackRefundedStatus" />
										<label class="custom-control-label" for="feedbackRefundedStatus3">
											退款处理中/稍后退款
										</label>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-6"><label for="feedbackAmountStopped"
											class="col-form-label">退款金额:</label>
										<argon-input id="feedbackAmountStopped" type="number"
											placeholder="止付金额或部分退款金额" v-model="feedbackAmountStopped" />
									</div>
									<div class="col-lg-6">
										<label for="feedbackTime" class="col-form-label">退款时间:</label>
										<argon-input id="feedbackTime" type="datetime-local" v-model="feedbackTime" />
									</div>

								</div>
								<label for="feedbackFirstPartyFraud" class="col-form-label">是否为欺诈:</label>
								<div class="d-flex justify-content-start">
									<div class="form-check">
										<input id="feedbackFirstPartyFraud1" class="form-check-input" type="radio"
											name="feedbackFirstPartyFraud" value="" v-model="feedbackFirstPartyFraud"
											checked />
										<label class="custom-control-label" for="feedbackFirstPartyFraud1">
											不填写
										</label>
									</div>
									<div class="form-check ms-3">
										<input id="feedbackFirstPartyFraud2" class="form-check-input" type="radio"
											name="feedbackFirstPartyFraud" value="yes" v-model="feedbackFirstPartyFraud" />
										<label class="custom-control-label" for="feedbackFirstPartyFraud2">
											是
										</label>
									</div>
									<div class="form-check ms-3">
										<input id="feedbackFirstPartyFraud3" class="form-check-input" type="radio"
											name="feedbackFirstPartyFraud" value="no" v-model="feedbackFirstPartyFraud" />
										<label class="custom-control-label" for="feedbackFirstPartyFraud3">
											否
										</label>
									</div>
								</div>
								<label for="feedbackMerchantOrderId" class="col-form-label">商家匹配订单流水号:</label>
								<argon-input id="feedbackMerchantOrderId" type="text" placeholder="商家匹配订单流水号" v-model="feedbackMerchantOrderId" />
								<div class="form-group">
									<label for="feedbackComments" class="col-form-label">处理意见:</label>
									<textarea id="feedbackComments" class="form-control"
										placeholder="选填,备注信息" v-model="feedbackComments"></textarea>
								</div>
							</form>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">取消</button>
							<button type="button" class="btn bg-gradient-primary" @click="feedbackOrder($event)" :disabled="isFeedbackOrder"><span v-if="isFeedbackOrder" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 反馈</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString,
		formateOrderSurplusDate
	} from '@/utils/tools.js';
	import Choices from "choices.js";
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import ArgonAvatar from "@/components/ArgonAvatar.vue"; // 头像
	import ArgonBadge from "@/components/ArgonBadge.vue"; // 标签
	import ArgonInput from "@/components/ArgonInput.vue"; // 输入框

	export default {
		name: "EthocaOrderDetails",
		components: {
			ArgonButton,
			ArgonAvatar,
			ArgonBadge,
			ArgonInput,
		},
		data() {
			return {
				orderDetails: [],
				choicesFraud: [{
						value: '',
						label: '请选择处理结果',
						selected: true,
						disabled: true,
					},
					{
						value: 'stopped',
						label: '通过退款/稍后退款阻止争议',
					},
					{
						value: 'partially_stopped',
						label: '通过预警停止部分交易',
					},
					{
						value: 'previously_cancelled',
						label: '收到预警前已发起退款',
					},
					{
						value: 'missed',
						label: '预警过晚，已经被拒付',
					},
					{
						value: 'notfound',
						label: '预警交易无法在商户系统找到',
					},
					{
						value: 'account_suspended',
						label: '持卡人账户被冻结',
					},
					{
						value: 'in_progress',
						label: '正在处理该预警',
					},
					{
						value: 'shipper_contacted',
						label: '需要进一步联系物流公司其并截止物流处理',
					},
					{
						value: 'other',
						label: '其他处理结果',
					},
				],
				choicesDispute: [{
						value: '',
						label: '请选择处理结果',
						selected: true,
						disabled: true,
					},
					{
						value: 'resolved',
						label: '通过退款/与客户联系阻止争议',
					},
					{
						value: 'previously_refunded',
						label: '收到预警前已发起退款',
					},
					{
						value: 'unresolved_dispute',
						label: '预警过晚，已经被拒付',
					},
					{
						value: 'notfound',
						label: '该预警无法在商户系统找到对应交易',
					},
					{
						value: 'other',
						label: '其他处理结果',
					},
				],
				errorTip: "",
				feedbackOutcome: "",
				feedbackRefundedStatus: 1,
				feedbackAmountStopped: "",
				feedbackTime: "",
				feedbackFirstPartyFraud: "",
				feedbackMerchantOrderId: "",
				feedbackComments: "",
				isFeedbackOrder: false,
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.$kuntnApi.getEthocaOrder({ // 获取Ethoca订单详情
					id: this.$route.params.id,
				}).then((result) => {
					this.orderDetails = result;

					// 下拉框数据
					let choicesData = [];
					if (result.type === 'fraud') {
						choicesData = this.choicesFraud;
					} else if (result.type === 'dispute') {
						choicesData = this.choicesDispute;
					}

					if (document.getElementById("feedbackOutcome")) { // 下拉框
						var element = document.getElementById("feedbackOutcome");
						return new Choices(element, {
							allowHTML: true,
							searchEnabled: false,
							itemSelectText: "点击选择",
							shouldSort: false, // false:按给定数据排序
							classNames: {
								containerOuter: "choices text-dark",
								itemChoice: "choices__item--choice text-grey",
							},
							choices: choicesData,
						});
					}
				}).catch(err => {
					console.log(err);
				})
			},
			async feedbackOrder(event) { // 反馈
				event.preventDefault(); // 防止表单提交
				
				this.errorTip = "";

				if (this.feedbackOutcome.length == 0) {
					this.errorTip = "请选择处理结果";
					return;
				} else if (this.feedbackRefundedStatus.length == 0) {
					this.errorTip = "请选择退款状态";
					return;
				}

				let feedbackTime = this.feedbackTime.length !== 0 ? new Date(this.feedbackTime).getTime() / 1000 : "";

				this.isFeedbackOrder = true;
				await this.$kuntnApi.feedbackEthocaOrder({
					id: this.$route.params.id,
					feedback_outcome: this.feedbackOutcome,
					feedback_refunded_status: this.feedbackRefundedStatus,
					feedback_amount_stopped: this.feedbackAmountStopped,
					feedback_time: feedbackTime,
					feedback_first_party_fraud: this.feedbackFirstPartyFraud,
					feedback_merchant_order_id: this.feedbackMerchantOrderId,
					feedback_comments: this.feedbackComments,
				}).then(() => {
					if (document.querySelector('.modal-backdrop')) { // 移除遮罩
						document.querySelector('.modal-backdrop').remove();
					}
					this.$router.push({path:'/order/ethoca-order-list'});
				}).catch(err => {
					this.errorTip = err;
					this.isFeedbackOrder = false;
				})
			},
			getStatus(orderStatus) {
				let status_obj = {
					des: "",
					color: ""
				};
				if (orderStatus == 0) { // 订单状态 0:待处理 1:已处理 2:拒绝处理
					status_obj.des = "待处理";
					status_obj.color = "dark";
				} else if (orderStatus == 1) {
					status_obj.des = "已处理";
					status_obj.color = "success";
				} else if (orderStatus == 2) {
					status_obj.des = "拒绝处理";
					status_obj.color = "danger";
				}
				return status_obj;
			},
			getType(type) {
				let type_desc = "";
				if (type == "fraud") {
					type_desc = "欺诈订单";
				} else if (type == "dispute") {
					type_desc = "争议订单";
				}
				return type_desc;
			},
			getOutcome(type,outcome) {
				let status_desc = "未知";
				
				if (type == "fraud") {
					switch (outcome) {
						case "stopped":
							status_desc = "通过退款/稍后退款阻止争议";
							break;
						case "partially_stopped":
							status_desc = "通过预警停止部分交易";
							break;
						case "previously_cancelled":
							status_desc = "收到预警前已发起退款";
							break;
						case "missed":
							status_desc = "预警过晚，已经被拒付";
							break;
						case "notfound":
							status_desc = "预警交易无法在商户系统找到";
							break;
						case "account_suspended":
							status_desc = "持卡人账户被冻结";
							break;
						case "in_progress":
							status_desc = "正在处理该预警";
							break;
						case "shipper_contacted":
							status_desc = "需要进一步联系物流公司其并截止物流处理";
							break;
						case "other":
							status_desc = "其他处理结果";
							break;
					}
				} else if (type == "dispute") {
					switch (outcome) {
						case "resolved":
							status_desc = "通过退款/与客户联系阻止争议";
							break;
						case "previously_refunded":
							status_desc = "收到预警前已发起退款";
							break;
						case "unresolved_dispute":
							status_desc = "预警过晚，已经被拒付";
							break;
						case "notfound":
							status_desc = "该预警无法在商户系统找到对应交易";
							break;
						case "other":
							status_desc = "其他处理结果";
							break;
					}
				}
				return status_desc;
			},
			getRefundedStatus(status) {
				let status_desc = "";
				if (status == 0) {
					status_desc = "待反馈";
				} else if (status == 1) {
					status_desc = "已退款";
				} else if (status == 2) {
					status_desc = "未退款";
				} else if (status == 3) {
					status_desc = "退款处理中/稍后退款";
				}
				return status_desc;
			},
			getTime(time) {
				return dateToString(time);
			},
			getSurplusTime(time1, time2) {
				return formateOrderSurplusDate(time1, time2, 86400);
			}
		},
		mounted() {
			this.initializeData(); // 初始化数据
		}
	};
</script>

<style>
	.text-grey {
		color: #e5e5e5;
	}

	.is-highlighted {
		background: #fff !important;
		color: #000 !important;
	}
</style>