<template>
	<div class="container position-sticky z-index-sticky">
		<div class="row">
			<div class="col-12">
				<auth-navbar />
			</div>
		</div>
	</div>
	<main class="main-content mt-0">
		<div class="page-header align-items-start min-vh-50 pt-5 pb-11" style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg');
        background-position: top;
      ">
			<span class="mask bg-gradient-dark opacity-6"></span>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-5 text-center mx-auto">
						<h1 class="text-white mb-2 mt-5">注册</h1>
						<p class="text-lead text-white">
							鲲盾商户管理后台
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
				<div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
					<div class="card z-index-0">
						<div class="card-body">
							<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
								role="alert">
								<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
								<span class="alert-text">{{ errorTip }}</span>
								<button type="button" class="btn-close py-2" @click="errorTip = ''">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form role="form">
								<argon-input id="merchant_name" type="text" placeholder="商户昵称"
									aria-label="merchant_name" v-model="merchant_name" />
								<argon-input id="email" type="email" placeholder="邮箱" aria-label="Email"
									v-model="email" />
								<argon-input id="password" type="password" placeholder="密码" aria-label="Password"
									v-model="password" />
								<argon-input id="rePassword" type="password" placeholder="确认密码" aria-label="Repassword"
									v-model="rePassword" />
								<div class="mt-4 row">
									<div class="col-md-7">
										<argon-input id="verify_code" type="text" placeholder="验证码"
											aria-label="Verify_code" v-model="verify_code" />
									</div>
									<div class="col-md-5">
										<argon-button size="md" color="primary" variant="gradient" fullWidth="true"
											@click="sendCode($event)">
											{{ count == 60 ? "发送验证码" : `${count}s` }}
										</argon-button>
									</div>
								</div>
								<argon-checkbox checked>
									<label class="form-check-label" for="flexCheckDefault">
										我同意
										<a href="javascript:;" class="text-dark font-weight-bolder">使用条款和条件</a>
									</label>
								</argon-checkbox>
								<div class="text-center">
									<argon-button full-width color="primary" variant="gradient" class="my-4 mb-2" @click="signup($event)" :disabled="isSignup"><span v-if="isSignup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 注册</argon-button>
								</div>
							</form>
						</div>
						<div class="card-footer text-center pt-0 px-lg-2 px-1">
							<p class="mb-4 text-sm mx-auto">
								已有商户账号?
								<router-link :to="{ name: '登陆' }" class="text-dark font-weight-bolder">
									登陆
								</router-link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
	<auth-footer />
</template>

<script>
	import AuthNavbar from "@/components/Auth/Navbar.vue"; // 头部导航
	import AuthFooter from "@/components/Auth/Footer.vue"; // 底部
	import ArgonInput from "@/components/ArgonInput.vue"; // 输入框
	import ArgonCheckbox from "@/components/ArgonCheckbox.vue"; // 复选框
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	const body = document.getElementsByTagName("body")[0];
	import {
		mapMutations
	} from "vuex";

	export default {
		name: "Signup",
		components: {
			AuthNavbar,
			AuthFooter,
			ArgonInput,
			ArgonCheckbox,
			ArgonButton
		},
		data() {
			return {
				merchant_name: "",
				email: "",
				password: "",
				rePassword: "",
				verify_code: "",
				count: 60,
				timerSetInterval: null,
				errorTip: "",
				isSignup: false
			};
		},
		created() {
			this.$store.state.hideConfigButton = true;
			this.toggleDefaultLayout();
			body.classList.remove("bg-gray-100");
		},
		beforeUnmount() {
			this.$store.state.hideConfigButton = false;
			this.toggleDefaultLayout();
			body.classList.add("bg-gray-100");
		},
		methods: {
			...mapMutations(["toggleDefaultLayout"]),
			async signup(event) { // 注册
				event.preventDefault(); //防止表单提交
				this.errorTip = ""

				if (this.merchant_name.length == 0) {
					this.errorTip = "请输入商户昵称"
					return;
				} else if (this.email.length == 0 || !this.checkEmail(this.email)) {
					this.errorTip = "请输入正确的邮箱"
					return;
				} else if (this.password.length == 0) {
					this.errorTip = "请输入密码"
					return;
				} else if (this.rePassword.length == 0) {
					this.errorTip = "请输入确认密码"
					return;
				} else if (this.verify_code.length != 6) {
					this.errorTip = "请输入正确的验证码"
					return;
				} else if (this.password !== this.rePassword) {
					this.errorTip = "确认密码不一致"
					return;
				}
				
				this.isSignup = true
				await this.$kuntnApi.signup({
					merchant_name: this.merchant_name,
					email: this.email,
					password: this.password,
					verify_code: this.verify_code,
				}).catch(err => {
					this.errorTip = err
					this.isSignup = false
				})
			},
			sendCode(event) { // 发送验证码
				event.preventDefault(); //防止表单提交
				this.errorTip = ""

				if (this.count < 60) {
					return;
				}
				if (this.email.length == 0 || !this.checkEmail(this.email)) {
					this.errorTip = "请输入正确的邮箱"
					return;
				}

				this.countDown()
				this.$kuntnApi.sendCode({
					email: this.email
				}).catch(err => {
					clearInterval(this.timerSetInterval);
					this.count = 60;
					this.errorTip = err
				})
			},
			countDown() { // 倒计时
				let that = this;
				this.count = 59;
				this.timerSetInterval = setInterval(() => {
					if (that.count > 0) {
						that.count -= 1;
					} else {
						clearInterval(that.timerSetInterval);
						that.count = 60;
					}
				}, 1000);
			},
			checkEmail(email) { // 检查邮箱
				let regEmail = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
				if (!regEmail.test(email)) {
					return false;
				}
				return true;
			}
		},
	}
</script>