<template>
	<div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
		<ul class="navbar-nav">
			<li class="nav-item">
				<sidenav-collapse nav-text="首页" :collapse="false" url="#" :aria-controls="''" collapse-ref="/index"
					:class="getRoute() === 'index' ? 'active' : ''">
					<template #icon>
						<i class="ni ni-shop text-primary text-sm opacity-10" :class="
	            $store.state.sidebarType === 'bg-default' ||
	            $store.state.darkMode
	              ? 'text-white'
	              : 'text-dark'
	          "></i>
					</template>
				</sidenav-collapse>
			</li>
			<li class="mt-3 nav-item">
				<h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
					:class="$store.state.isRTL ? 'me-4' : 'ms-2'">
					拒付管理
				</h6>
			</li>
			<li class="nav-item">
				<sidenav-collapse nav-text="商家管理" :collapse="false" url="#" :aria-controls="''"
					collapse-ref="/account/account-list" :class="getRoute() === 'account' ? 'active' : ''">
					<template #icon>
						<i class="ni ni-badge text-success text-sm opacity-10"></i>
					</template>
				</sidenav-collapse>
			</li>
			<li class="nav-item">
				<sidenav-collapse collapse-ref="order" nav-text="订单管理"
					:class="getRoute() === 'order' ? 'active' : ''" :defaultExpanded="getRoute() === 'order' ? true : false">
					<template #icon>
						<i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
					</template>

					<template #list>
						<ul class="nav ms-4">
							<sidenav-item :to="{ name: 'Ethoca订单列表' }" mini-icon="Ethoca" text="Ethoca订单" />
							<sidenav-item :to="{ name: 'VISA RDR订单列表' }" mini-icon="RDR" text="VISA RDR订单" />
							<sidenav-item :to="{ name: '充值订单列表' }" mini-icon="Recharge" text="充值订单" />
						</ul>
					</template>
				</sidenav-collapse>
			</li>
			<li class="nav-item">
				<sidenav-collapse nav-text="账单" :collapse="false" url="#" :aria-controls="''"
					collapse-ref="/bill/bill" :class="getRoute() === 'bill' ? 'active' : ''">
					<template #icon>
						<i class="ni ni-money-coins text-primary text-sm opacity-10"></i>
					</template>
				</sidenav-collapse>
			</li>
			<li class="nav-item">
				<sidenav-collapse collapse-ref="merchant" nav-text="商户管理"
					:class="getRoute() === 'merchant' ? 'active' : ''" :defaultExpanded="getRoute() === 'merchant' ? true : false">
					<template #icon>
						<i class="ni ni-single-02 text-light text-sm opacity-10"></i>
					</template>

					<template #list>
						<ul class="nav ms-4">
							<sidenav-item :to="{ name: '商户中心' }" mini-icon="商户" text="商户中心" />
							<sidenav-item :to="{ name: '资产明细列表' }" mini-icon="明细" text="资产明细" />
						</ul>
					</template>
				</sidenav-collapse>
			</li>
			<li class="mt-3 nav-item">
				<hr class="mt-0 horizontal dark" />
				<h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6"
					:class="$store.state.isRTL ? 'me-4' : 'ms-2'">
					文档
				</h6>
			</li>
		</ul>
	</div>
	<div class="mt-3 sidenav-footer">
		<sidenav-card :card="{
        title: '需要帮助?',
        description: '请查看在线文档',
        links: [
          {
            label: '接口文档',
            route:
              'https://api.kuntn.com/docs',
            color: 'dark'
          }
        ]
      }" />
	</div>
</template>
<script>
	import SidenavItem from "./SidenavItem.vue";
	import SidenavCollapse from "./SidenavCollapse.vue";
	import SidenavCard from "./SidenavCard.vue";

	export default {
		name: "SidenavList",
		components: {
			SidenavItem,
			SidenavCollapse,
			SidenavCard,
		},
		methods: {
			getRoute() {
				const routeArr = this.$route.path.split("/");
				return routeArr[1];
			}
		}
	};
</script>