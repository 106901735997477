<template>
	<div class="card">
		<div class="p-3 card-body">
			<div class="d-flex">
				<div class="my-auto ms-3">
					<h6><a :href="`/account/edit-account/${accountId}`">{{ companyName }}</a></h6>
					<div class="my-3">
						<span class="badge" :class="getStatus.ethocaClassName">{{ getStatus.ethocaStatusDes }}</span>
						<span @click="$router.push({path:'/account/ethoca-descriptor-list/' + accountId});"
							class="badge badge-secondary ms-2 cursor-pointer">查看描述符</span>
					</div>
					<div class="my-2">
						<span class="badge" :class="getStatus.rdrClassName">{{ getStatus.rdrStatusDes }}</span>
						<span @click="$router.push({path:'/account/rdr-descriptor-list/' + accountId});"
							class="badge badge-secondary ms-2 cursor-pointer">查看描述符</span>
					</div>
				</div>
				<div class="ms-auto">
					<div class="dropdown">
						<button id="navbarDropdownMenuLink" class="btn btn-link text-secondary ps-0 pe-2"
							data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i class="text-lg fa fa-ellipsis-v"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
							aria-labelledby="navbarDropdownMenuLink">
							<a v-for="(drop, index) in dropdown" :key="index" class="dropdown-item border-radius-md"
								:href="drop.route">{{ drop.label }}
							</a>
						</div>
					</div>
				</div>
			</div>
			<hr class="horizontal light" />
			<div class="row">
				<div class="col-6">
					<h6 class="mb-0 text-sm">
						<div class="avatar-group">
							<a href="javascript:;" class="avatar avatar-xs rounded-circle m-2" data-bs-toggle="tooltip"
								title="Ethoca">
								<img :src="require(`@/assets/images/ethoca${ethocaStatus !== 1 ? '-dark' : ''}.jpg`)" />
							</a>
							<a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip"
								title="VISA快速争议处理">
								<img :src="require(`@/assets/images/rdr${rdrStatus !== 1 ? '-dark' : ''}.jpg`)" />
							</a>
						</div>
					</h6>
					<p class="mb-0 text-sm text-secondary font-weight-bold">
						预警模式
					</p>
				</div>
				<div class="col-6 text-end" style="align-self: flex-end;">
					<h6 class="mb-0 text-sm">{{ getTime }}</h6>
					<p class="mb-0 text-sm text-secondary font-weight-bold">创建时间</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString
	} from "@/utils/tools.js";

	export default {
		name: "AccountListCard",
		props: {
			accountId: {
				type: String,
				default: "",
			},
			companyName: {
				type: String,
				default: "",
			},
			ethocaDescriptor: {
				type: String,
				default: "",
			},
			rdrDescriptor: {
				type: String,
				default: "",
			},
			ethocaStatus: {
				type: Number,
				default: 0,
			},
			rdrStatus: {
				type: Number,
				default: 0,
			},
			createTime: {
				type: Number,
				default: 0,
			},
			dropdown: {
				type: Array,
				default: () => [],
				label: String,
				route: String,
			},
		},
		computed: {
			getStatus() {
				let ethocaStatusDes = "";
				let ethocaClassName = "";
				if (this.ethocaStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					ethocaStatusDes = "Ethoca未开启";
					ethocaClassName = "badge-secondary";
				} else if (this.ethocaStatus == 1) {
					ethocaStatusDes = "Ethoca预警中";
					ethocaClassName = "badge-success";
				} else if (this.ethocaStatus == 2) {
					ethocaStatusDes = "Ethoca审核中";
					ethocaClassName = "badge-warning";
				} else if (this.ethocaStatus == 3) {
					ethocaStatusDes = "Ethoca审核失败";
					ethocaClassName = "badge-danger";
				} else if (this.ethocaStatus == 4) {
					ethocaStatusDes = "Ethoca关闭中";
					ethocaClassName = "badge-secondary";
				}

				let rdrStatusDes = "";
				let rdrClassName = "";
				if (this.rdrStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					rdrStatusDes = "快速争议处理未开启";
					rdrClassName = "badge-secondary";
				} else if (this.rdrStatus == 1) {
					rdrStatusDes = "快速争议处理预警中";
					rdrClassName = "badge-success";
				} else if (this.rdrStatus == 2) {
					rdrStatusDes = "快速争议处理审核中";
					rdrClassName = "badge-warning";
				} else if (this.rdrStatus == 3) {
					rdrStatusDes = "快速争议处理审核失败";
					rdrClassName = "badge-danger";
				} else if (this.rdrStatus == 4) {
					rdrStatusDes = "快速争议处理关闭中";
					rdrClassName = "badge-secondary";
				}

				return {
					ethocaStatusDes,
					ethocaClassName,
					rdrStatusDes,
					rdrClassName,
				};
			},
			getTime() {
				return dateToString(this.createTime);
			},
		},
	};
</script>