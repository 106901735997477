<template>
	<div class="card shadow-lg mx-4 card-profile-bottom">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<div class="d-flex align-items-center">
							<h6 class="mb-0">账单列表</h6>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table align-items-center mb-0 min-height-100">
							<thead>
								<tr>
									<th
										class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-4">
										账单ID
									</th>
									<th class="text-uppercase text-secondary text-xxs font-weight-bolder ps-2">
										账单日期
									</th>
									<th
										class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
										账单金额
									</th>
									<th
										class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
										账单状态
									</th>
									<th
										class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
										操作
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in billList" :key="item.id">
									<td>
										<p class="text-sm text-secondary mb-0 ps-3"><a :href="`/bill/bill-details/${item.id}`">{{ item.order_id }}</a></p>
									</td>
									<td>
										<h6 class="mb-0 text-sm">{{ item.bill_date }}</h6>
									</td>
									<td>
										<p class="text-secondary mb-0 text-sm">{{ item.amount }}
											{{ item.currency }}</p>
									</td>
									<td>
										<span class="badge badge-dot me-4">
											<i :class="getStatus(item.bill_status).className"></i>
											<span class="text-light text-sm">{{ getStatus(item.bill_status).des }}</span>
										</span>
									</td>
									<td class="align-middle text-center">
										<span class="text-sm"><a :href="`/bill/bill-details/${item.id}`"><i class="fas fa-eye text-secondary"></i></a></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Bill",
		data() {
			return {
				billList: [],
			};
		},
		methods: {
			async getBillList() {
				this.$kuntnApi.getBillList({
					bill_status: 'all',
				}).then((result) => { // 获取账单列表
					this.billList = result.list;
				}).catch(err => {
					console.log(err);
				})
			},
			getStatus(billStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (billStatus == 0) { // 订单状态 0:待支付 1:已支付
					status_obj.des = "待支付";
					status_obj.className = "bg-light";
				} else if (billStatus == 1) {
					status_obj.des = "已支付";
					status_obj.className = "bg-success";
				}
				return status_obj;
			},
		},
		mounted() {
			this.getBillList();
		},
		beforeMount() {
			this.$store.state.layout = "custom"; // 显示顶部背景图
		},
		beforeUnmount() {
			this.$store.state.layout = "default"; // 不显示顶部背景图
		},
	};
</script>