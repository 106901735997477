<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">资产明细列表</h5>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
									<button class="mt-1 mb-0 ms-2 btn btn-outline-primary btn-sm export mt-sm-0"
										data-type="csv" type="button" name="button">

										<span class="btn-inner--icon"><i class="ni ni-archive-2 ni-lg"></i></span> <span
											class="btn-inner--text">导出记录</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="px-0 pb-0 card-body">
						<div class="table-responsive">
							<table id="order-list" class="table table-flush">
								<thead class="thead-light">
									<tr>
										<th>来源订单号</th>
										<th>变动金额</th>
										<th>变动类型</th>
										<th>创建时间</th>
									</tr>
								</thead>
								<tfoot>
									<tr>
										<th>来源订单号</th>
										<th>变动金额</th>
										<th>变动类型</th>
										<th>创建时间</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString
	} from "@/utils/tools.js";
	import {
		DataTable
	} from "simple-datatables";

	export default {
		name: "getAssetLogList",
		data() {
			return {
				dataTable: {},
			};
		},
		methods: {
			async getAssetLogList(action) {
				this.$kuntnApi.getAssetLogList({}).then((result) => { // 获取资产明细列表
					let orderList = [];
					result.list.forEach((item) => {
						let tableData = {
							'来源订单号': '<div class="d-flex"><h6 class="my-auto ms-3">' + item
								.source_order_id +
								'</h6></div>',
							'变动金额': '<span class="text-sm">' + item.amount + ' ' + item.currency + '</span>',
							'变动类型': '<span class="badge badge-sm ' + this.getType(item
									.change_type)
								.className + '">' + this.getType(item.change_type).des +
								'</span>',
							'创建时间': '<span class="text-sm">' + this.getTime(item.create_time) +
								'</span>',
						};
						orderList.push(tableData);
					})
					if (action == "update") {
						this.dataTable.destroy();
						this.dataTable.init();
					}
					this.dataTable.insert(orderList);
				}).catch(err => {
					console.log(err);
				})
			},
			getType(type) {
				let type_obj = {
					des: "",
					className: ""
				};
				if (type == "recharge") {
					type_obj.des = "充值";
					type_obj.className = "badge-success";
				} else if (type == "bill_pay") {
					type_obj.des = "账单支付";
					type_obj.className = "badge-warning";
				}
				return type_obj;
			},
			getTime(time) {
				return dateToString(time);
			},
		},
		mounted() {
			let that = this;

			if (document.getElementById("order-list")) {
				this.dataTable = new DataTable("#order-list", {
					searchable: true,
					fixedHeight: false,
					perPage: 10,
					labels: {
						placeholder: "搜索...",
						perPage: "{select} 每页显示条数",
						noRows: "没有更多记录了",
						info: "显示第{start}到第{end}条记录, 共{rows}条记录",
						noResults: "没有找到符合您搜索的记录",
					}
				});

				this.getAssetLogList();

				document.querySelectorAll(".export").forEach(function(el) {
					el.addEventListener("click", function() {
						var type = el.dataset.type;

						var data = {
							type: type,
							filename: "AssetLog-" + type,
						};

						if (type === "csv") {
							data.columnDelimiter = "|";
						}

						that.dataTable.export(data);
					});
				});
			}
		},
	};
</script>

<style>
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a,
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a:hover {
		background-image: linear-gradient(310deg, #5f72e4 0%, #7f60e4 100%);
	}
</style>