import {
	createRouter,
	createWebHistory
} from "vue-router";
import store from '@/store/index.js'
import Index from "@/views/Index.vue";
import Signin from "@/views/auth/Signin.vue";
import Signup from "@/views/auth/Signup.vue";
import AccountList from "@/views/account/AccountList.vue";
import AddAccount from "@/views/account/AddAccount.vue";
import EditAccount from "@/views/account/EditAccount.vue";
import EthocaDescriptorList from "@/views/account/EthocaDescriptorList.vue";
import RDRDescriptorList from "@/views/account/RDRDescriptorList.vue";
import EthocaOrderList from "@/views/order/EthocaOrderList.vue";
import EthocaOrderDetails from "@/views/order/EthocaOrderDetails.vue";
import RDROrderList from "@/views/order/RDROrderList.vue";
import RDROrderDetails from "@/views/order/RDROrderDetails.vue";
import RechargeOrderList from "@/views/order/RechargeOrderList.vue";
import MerchantCenter from "@/views/merchant/MerchantCenter.vue";
import Recharge from "@/views/merchant/Recharge.vue";
import AssetLogList from "@/views/merchant/AssetLogList.vue";
import Bill from "@/views/bill/Bill.vue";
import BillDetails from "@/views/bill/BillDetails.vue";

const routes = [{
		path: "/",
		name: "/",
		redirect: "/index"
	},
	{
		path: "/index",
		name: "首页",
		meta: {
			requireAuth: true, // 需要登录
		},
		component: Index
	},
	{
		path: "/auth/signin",
		name: "登陆",
		component: Signin
	},
	{
		path: "/auth/signup",
		name: "注册",
		component: Signup
	},
	{
		path: "/account/account-list",
		name: "商家管理",
		meta: {
			requireAuth: true,
		},
		component: AccountList
	},
	{
		path: "/account/add-account",
		name: "添加商家",
		meta: {
			requireAuth: true,
		},
		component: AddAccount
	},
	{
		path: "/account/edit-account/:id",
		name: "编辑商家",
		meta: {
			requireAuth: true,
		},
		component: EditAccount
	},
	{
		path: "/account/ethoca-descriptor-list/:id",
		name: "Ethoca描述符列表",
		meta: {
			requireAuth: true,
		},
		component: EthocaDescriptorList
	},
	{
		path: "/account/rdr-descriptor-list/:id",
		name: "VISA RDR描述符列表",
		meta: {
			requireAuth: true,
		},
		component: RDRDescriptorList
	},
	{
		path: "/order/rdr-order-details/:id",
		name: "RDR订单详情",
		meta: {
			requireAuth: true,
		},
		component: RDROrderDetails
	},
	{
		path: "/order/ethoca-order-list",
		name: "Ethoca订单列表",
		meta: {
			requireAuth: true,
		},
		component: EthocaOrderList
	},
	{
		path: "/order/ethoca-order-details/:id",
		name: "Ethoca订单详情",
		meta: {
			requireAuth: true,
		},
		component: EthocaOrderDetails
	},
	{
		path: "/order/rdr-order-list",
		name: "VISA RDR订单列表",
		meta: {
			requireAuth: true,
		},
		component: RDROrderList
	},
	{
		path: "/order/recharge-order-list",
		name: "充值订单列表",
		meta: {
			requireAuth: true,
		},
		component: RechargeOrderList
	},
	{
		path: "/order/rdr-order-details/:id",
		name: "RDR订单详情",
		meta: {
			requireAuth: true,
		},
		component: RDROrderDetails
	},
	{
		path: "/merchant/merchant-center",
		name: "商户中心",
		meta: {
			requireAuth: true,
		},
		component: MerchantCenter
	},
	{
		path: "/merchant/recharge",
		name: "充值",
		meta: {
			requireAuth: true,
		},
		component: Recharge
	},
	{
		path: "/merchant/asset-log-list",
		name: "资产明细列表",
		meta: {
			requireAuth: true,
		},
		component: AssetLogList
	},
	{
		path: "/bill/bill",
		name: "账单",
		meta: {
			requireAuth: true,
		},
		component: Bill
	},
	{
		path: "/bill/bill-details/:id",
		name: "账单详情",
		meta: {
			requireAuth: true,
		},
		component: BillDetails
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
	if (window.localStorage.getItem("merchantInfo")) { // 读取本地商户信息
		store.state.merchantInfo = JSON.parse(window.localStorage.getItem("merchantInfo"));
	}

	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (Object.keys(store.state.merchantInfo).length !== 0) { // 判断是否登陆
			next();
		} else {
			store.commit('logout');
			next({
				path: '/auth/signin'
			})
		}
	} else {
		next();
	}
})

export default router;