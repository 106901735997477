<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">充值订单列表</h5>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
									<div class="dropdown d-inline">
										<argon-button id="navbarDropdownMenuLink2" color="primary" variant="gradient"
											size="sm" class="dropdown-toggle" data-bs-toggle="dropdown"
											aria-expanded="false">状态筛选</argon-button>
										<ul class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
											aria-labelledby="navbarDropdownMenuLink2" style>
											<li @click="filterStatus(0)">
												<a :class="`dropdown-item border-radius-md ${rechargeStatus == 0 ? 'bg-white' : ''}`"
													href="javascript:;">待充值</a>
											</li>
											<li @click="filterStatus(1)">
												<a :class="`dropdown-item border-radius-md ${rechargeStatus == 1 ? 'bg-white' : ''}`"
													href="javascript:;">已充值</a>
											</li>
											<li @click="filterStatus(2)">
												<a :class="`dropdown-item border-radius-md ${rechargeStatus == 2 ? 'bg-white' : ''}`"
													href="javascript:;">充值失败</a>
											</li>
											<li>
												<hr class="horizontal light my-2" />
											</li>
											<li @click="filterStatus('all')">
												<a class="dropdown-item border-radius-md text-danger"
													href="javascript:;">清除筛选</a>
											</li>
										</ul>
									</div>
									<button class="mt-1 mb-0 ms-2 btn btn-outline-primary btn-sm export mt-sm-0"
										data-type="csv" type="button" name="button">

										<span class="btn-inner--icon"><i class="ni ni-archive-2 ni-lg"></i></span> <span
											class="btn-inner--text">导出记录</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="px-0 pb-0 card-body">
						<div class="table-responsive">
							<table id="order-list" class="table table-flush">
								<thead class="thead-light">
									<tr>
										<th>订单号</th>
										<th>来源金额</th>
										<th>目标金额</th>
										<th>手续费</th>
										<th>汇率</th>
										<th>状态</th>
										<th>创建时间</th>
									</tr>
								</thead>
								<tfoot>
									<tr>
										<th>订单号</th>
										<th>来源金额</th>
										<th>目标金额</th>
										<th>手续费</th>
										<th>汇率</th>
										<th>状态</th>
										<th>创建时间</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString
	} from "@/utils/tools.js";
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import {
		DataTable
	} from "simple-datatables";

	export default {
		name: "RechargeOrderList",
		components: {
			ArgonButton,
		},
		data() {
			return {
				dataTable: {},
				rechargeStatus: "all",
			};
		},
		methods: {
			async getRechargeOrderList(action) {
				this.$kuntnApi.getRechargeOrderList({ // 获取充值订单列表
					recharge_status: this.rechargeStatus,
				}).then((result) => {
					let orderList = [];
					result.list.forEach((item) => {
						let tableData = {
							'订单号': '<div class="d-flex"><h6 class="my-auto ms-3">' + item
								.order_id +
								'</h6></div>',
							'来源金额': '<span class="text-sm">' + item.source_amount + ' ' + item.source_currency +
								'</span>',
							'目标金额': '<span class="text-sm">' + item.dest_amount + ' ' + item.dest_currency +
								'</span>',
							'手续费': '<span class="text-sm">' + item.recharge_fee + ' ' + item.source_currency +
								'</span>',
							'汇率': '<span class="text-sm">' + item.rate +
								'</span>',
							'状态': '<span class="badge badge-sm ' + this.getStatus(item
									.recharge_status)
								.className + '">' + this.getStatus(item.recharge_status).des +
								'</span>',
							'创建时间': '<span class="text-sm">' + this.getTime(item.create_time) +
								'</span>',
						};
						orderList.push(tableData);
					})
					if (action == "update") {
						this.dataTable.destroy();
						this.dataTable.init();
					}
					this.dataTable.insert(orderList);
				}).catch(err => {
					console.log(err);
				})
			},
			filterStatus(rechargeStatus) {
				this.rechargeStatus = rechargeStatus;
				this.getRechargeOrderList("update");
			},
			getStatus(rechargeStatus) {
				let status_obj = {
					des: "",
					className: ""
				};
				if (rechargeStatus == 0) { // 充值状态 0:待充值 1:已充值 2:充值失败
					status_obj.des = "待充值";
					status_obj.className = "badge-dark";
				} else if (rechargeStatus == 1) {
					status_obj.des = "已充值";
					status_obj.className = "badge-success";
				} else if (rechargeStatus == 2) {
					status_obj.des = "充值失败";
					status_obj.className = "badge-danger";
				}
				return status_obj;
			},
			getTime(time) {
				return dateToString(time);
			},
		},
		mounted() {
			let that = this;

			if (document.getElementById("order-list")) {
				this.dataTable = new DataTable("#order-list", {
					searchable: true,
					fixedHeight: false,
					perPage: 10,
					labels: {
						placeholder: "搜索...",
						perPage: "{select} 每页显示条数",
						noRows: "没有更多记录了",
						info: "显示第{start}到第{end}条记录, 共{rows}条记录",
						noResults: "没有找到符合您搜索的记录",
					}
				});

				this.getRechargeOrderList();

				document.querySelectorAll(".export").forEach(function(el) {
					el.addEventListener("click", function() {
						var type = el.dataset.type;

						var data = {
							type: type,
							filename: "RechargeOrder-" + type,
						};

						if (type === "csv") {
							data.columnDelimiter = "|";
						}

						that.dataTable.export(data);
					});
				});
			}
		},
	};
</script>

<style>
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a,
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a:hover {
		background-image: linear-gradient(310deg, #5f72e4 0%, #7f60e4 100%);
	}
</style>