<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Ethoca描述符列表</h5>
								<p class="mb-0 text-sm">
									主要预警MasterCard拒付，同时可预警其他卡品牌
								</p>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
									<argon-button @click="$router.back();" color="light" variant="gradient" class="mb-0">返回</argon-button>
									<argon-button color="primary" variant="gradient" class="ms-3 mb-0" data-bs-toggle="modal"
										data-bs-target="#addDescriptor">添加描述符</argon-button>
								</div>
							</div>
						</div>
					</div>
					<div class="px-0 pb-0 card-body">
						<div class="table-responsive">
							<table id="descriptor-list" class="table table-flush">
								<thead class="thead-light">
									<tr>
										<th>描述符</th>
										<th>匹配模式</th>
										<th>状态</th>
										<th>创建时间</th>
									</tr>
								</thead>
								<tfoot>
									<tr>
										<th>描述符</th>
										<th>匹配模式</th>
										<th>状态</th>
										<th>创建时间</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="addDescriptor" tabindex="-1" role="dialog" aria-labelledby="descriptorTitle"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content bg-dark">
					<div class="modal-header">
						<h5 class="modal-title">添加描述符</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
						</button>
					</div>
					<div class="modal-body">
						<div v-if="errorTip" class="alert alert-danger text-white alert-dismissible show fade py-2"
							role="alert">
							<span class="alert-icon"><i class="ni ni-fat-remove ni-lg"></i></span>
							<span class="alert-text">{{ errorTip }}</span>
							<button type="button" class="btn-close py-2" @click="errorTip = ''">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form>
							<div class="input-group my-auto ms-sm-auto">
								<input type="text" class="form-control" placeholder="Ethoca描述符(Descriptor)"
									aria-label="Ethoca描述符(Descriptor)" v-model="descriptor">
								<div class="dropdown">
									<argon-button id="navbarDropdownMenuLink" color="primary" variant="gradient"
										size="md"
										class="dropdown-toggle border-top-start-radius-0 border-bottom-start-radius-0"
										data-bs-toggle="dropdown"
										aria-expanded="false">{{ matchModelDes }}</argon-button>
									<ul class="dropdown-menu dropdown-menu-lg-start px-1 py-1 bg-white"
										aria-labelledby="navbarDropdownMenuLink" style>
										<li @click="selectMatchModel('exact')">
											<a class="dropdown-item border-radius-md" href="javascript:;">精准匹配</a>
										</li>
										<li @click="selectMatchModel('start')">
											<a class="dropdown-item border-radius-md" href="javascript:;">以开头匹配</a>
										</li>
									</ul>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">取消</button>
						<button type="button" class="btn bg-gradient-primary" @click="addDescriptor($event)"
							:disabled="isAddDescriptor"><span v-if="isAddDescriptor"
								class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 添加</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString
	} from "@/utils/tools.js";
	import ArgonButton from "@/components/ArgonButton.vue"; // 按钮
	import {
		DataTable
	} from "simple-datatables";

	export default {
		name: "EthocaDescriptorList",
		components: {
			ArgonButton,
		},
		data() {
			return {
				dataTable: {},
				matchModel: "exact",
				matchModelDes: "精准匹配",
				errorTip: "",
				descriptor: "",
				isAddDescriptor: false,
			};
		},
		methods: {
			async getDescriptorList(action) {
				this.$kuntnApi.getDescriptorList({ // 获取描述符列表
					account_id: this.$route.params.id,
					type: "ethoca",
				}).then((result) => {
					let descriptorList = [];
					result.list.forEach((item) => {
						let tableData = {
							'描述符': '<div class="d-flex"><h6 class="my-auto ms-3">' + item
								.descriptor + '</h6></div>',
							'匹配模式': '<span class="badge badge-sm ' + this.getModel(item
								.match_model).className + '">' + this.getModel(item
								.match_model).des + '</span>',
							'状态': '<span class="badge badge-sm ' + this.getStatus(item
								.descriptor_status).className + '">' + this.getStatus(item
								.descriptor_status).des + '</span>',
							'创建时间': '<span class="text-sm">' + this.getTime(item.create_time) +
								'</span>',
						};
						descriptorList.push(tableData);
					})
					if (action == "update") {
						this.dataTable.destroy();
						this.dataTable.init();
					}
					this.dataTable.insert(descriptorList);
				}).catch(err => {
					console.log(err);
				})
			},
			async addDescriptor(event) { // 添加描述符
				event.preventDefault(); // 防止表单提交
				this.errorTip = "";
			
				if (this.descriptor.length == 0) {
					this.errorTip = "请输入Ethoca描述符";
					return;
				}
				
				this.isAddDescriptor = true;
				await this.$kuntnApi.addDescriptor({
					account_id: this.$route.params.id,
					descriptor: this.descriptor,
					match_model: this.matchModel,
					type: "ethoca",
				}).then(() => {
					if (document.querySelector('.modal-backdrop')) { // 移除遮罩
						document.querySelector('.modal-backdrop').remove();
					}
					location.reload();
				}).catch(err => {
					this.errorTip = err;
					this.isAddDescriptor = false;
				})
			},
			getStatus(descriptorStatus) {
				let des = "";
				let className = "";
				if (descriptorStatus == 0) { // 0:已关闭 1:已启用 2:审核中 3:审核失败 4:关闭中
					des = "未开启";
					className = "badge-secondary";
				} else if (descriptorStatus == 1) {
					des = "已启用";
					className = "badge-success";
				} else if (descriptorStatus == 2) {
					des = "审核中";
					className = "badge-warning";
				} else if (descriptorStatus == 3) {
					des = "审核失败";
					className = "badge-danger";
				} else if (descriptorStatus == 4) {
					des = "关闭中";
					className = "badge-secondary";
				}

				return {
					des,
					className,
				};
			},
			getModel(model) {
				let model_obj = {
					des: "",
					className: ""
				};
				if (model == "exact") {
					model_obj.des = "精准匹配";
					model_obj.className = "badge-primary";
				} else if (model == "start") {
					model_obj.des = "以开头匹配";
					model_obj.className = "badge-success";
				}
				return model_obj;
			},
			getTime(time) {
				return dateToString(time);
			},
			selectMatchModel(model) {
				this.matchModel = model;
				switch (model) {
					case "exact":
						this.matchModelDes = "精准匹配";
						break;
					case "start":
						this.matchModelDes = "以开头匹配";
						break;
				}
			},
		},
		mounted() {
			if (document.getElementById("descriptor-list")) {
				this.dataTable = new DataTable("#descriptor-list", {
					searchable: true,
					fixedHeight: false,
					perPage: 10,
					labels: {
						placeholder: "搜索...",
						perPage: "{select} 每页显示条数",
						noRows: "没有更多记录了",
						info: "显示第{start}到第{end}条记录, 共{rows}条记录",
						noResults: "没有找到符合您搜索的记录",
					}
				});

				this.getDescriptorList();
			}
		},
	};
</script>

<style>
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a,
	.dataTable-wrapper .dataTable-bottom .dataTable-pagination .dataTable-pagination-list .active a:hover {
		background-image: linear-gradient(310deg, #5f72e4 0%, #7f60e4 100%);
	}
</style>