<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 col-sm-10 mx-auto">
				<form class action="index.html" method="post">
					<div class="card my-sm-5">
						<div class="card-header text-center">
							<div class="row justify-content-between">
								<div class="col-md-6 text-start">
									<h5>
										{{ billDetails.bill_date }}账单
									</h5>
									<p class="d-block text-secondary">请在账单到期前完成支付，到期未支付会造成相关功能失效</p>
									<p>
										<argon-button color="white" variant="outline" class="mb-0"
											onclick="window.print()" type="button" name="button">打印账单</argon-button>
									</p>
								</div>
								<div class="col-lg-3 col-md-7 text-md-end text-start mt-5">
									<h6 class="d-block mt-2 mb-0">付款人: {{ merchantInfo.merchant_name }}</h6>
									<p class="text-secondary">
										{{ merchantInfo.email }}
									</p>
								</div>
							</div>
							<br />
							<div class="row justify-content-md-between">
								<div class="col-md-4 mt-auto">
									<h6 class="mb-0 text-start text-secondary">账单ID</h6>
									<h5 class="text-start mb-0">{{ billDetails.order_id }}</h5>
								</div>
								<div class="col-lg-5 col-md-7 mt-auto">
									<div class="row mt-md-5 mt-4 text-md-end text-start">
										<div class="col-md-6">
											<h6 class="text-secondary mb-0">出账时间:</h6>
										</div>
										<div class="col-md-6">
											<h6 class="text-dark mb-0">{{ getTime(billDetails.create_time) }}</h6>
										</div>
									</div>
									<div class="row text-md-end text-start">
										<div class="col-md-6">
											<h6 class="text-secondary mb-0">到期时间:</h6>
										</div>
										<div class="col-md-6">
											<h6 class="text-dark mb-0">{{ getDueTime(billDetails.create_time, 259200) }}
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<div class="table-responsive border-radius-lg">
										<table class="table text-right">
											<thead class="bg-default text-white">
												<tr>
													<th scope="col" class="text-start pe-2 ps-2 w-40">收费项目</th>
													<th scope="col" class="pe-2 w-30">商家</th>
													<th scope="col" class="pe-2 w-30">费用</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="item in billDetails.bill_details" :key="item.account_name">
													<td class="text-start">{{ item.item }}</td>
													<td class="ps-4">{{item.account_name}}</td>
													<td class="ps-4 h6">{{item.amount}} {{item.currency}}</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<th></th>
													<th class="h6 ps-4">费用总计</th>
													<th class="ps-4 h6">{{ billDetails.amount }}
														{{ billDetails.currency }} ≈ {{ billDetails.dest_amount }}
														{{ billDetails.dest_currency }} (汇率: {{ billDetails.rate }})
													</th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer mt-md-5 mt-4">
							<div class="row">
								<div class="col-lg-5 text-left">
									<h5>谢谢!</h5>
									<p class="text-secondary text-sm">
										如您对本账单有任何疑问，您可以联系我们
									</p>
									<h6 class="text-secondary mb-0">
										联系邮箱:
										<span class="text-light">support@kuntn.com</span>
									</h6>
								</div>
								<div class="col-lg-7 text-md-end mt-md-0 mt-3">
									<button type="button" name="button" class="btn btn-light"
										@click="$router.push({path:'/bill/bill'});">
										返回
									</button>
									<button v-if="billDetails.bill_status == 1" type="button"
										class="btn bg-success ms-3 text-white">账单已支付</button>
									<button v-else @click="pay()" type="button" variant="gradient"
										class="btn bg-gradient-primary ms-3" :disabled="isPay"><span v-if="isPay"
											class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span> 立即付款</button>

								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateToString,
		formateDueDate
	} from '@/utils/tools.js';
	import QRCode from 'qrcodejs2';
	import ArgonButton from "@/components/ArgonButton.vue";

	export default {
		name: "BillDetails",
		components: {
			ArgonButton,
		},
		data() {
			return {
				merchantInfo: {},
				billDetails: {},
				isPay: false,
				rechargeTimer: "",
			};
		},
		methods: {
			async initializeData() { // 初始化数据
				this.$kuntnApi.getMerchant({}).then((result) => { // 获取商户信息
					this.merchantInfo = result;
				}).catch(err => {
					console.log(err);
				});

				this.$kuntnApi.getBill({ // 获取账单详情
					id: this.$route.params.id,
				}).then((result) => {
					this.billDetails = result;
					this.billDetails.bill_details = JSON.parse(this.billDetails.bill_details);
				}).catch(err => {
					console.log(err);
				});
			},
			async pay() { // 付款
				this.isPay = true;
				await this.$kuntnApi.payBill({ // 支付账单
					id: this.$route.params.id,
				}).then(() => {
					location.reload();
				}).catch(err => {
					if (err == "Insufficient funds") { // 余额不足
						var rechargeAmount = this.billDetails.amount - this.merchantInfo.usd_balance;
						if (rechargeAmount < 1) {
							rechargeAmount = 1;
						}
						this.$kuntnApi.recharge({ // 充值
							amount: rechargeAmount,
							currency: this.billDetails.currency,
						}).then((result) => {
							this.$swal({
								title: '余额充值',
								html: '<div class="swal2-actions mt-0"><div class="text-secondary text-sm my-auto"><img width="15" src="' +
									require(`@/assets/images/wechat-pay.jpg`) +
									'" /> 使用微信支付将承担手续费，您的实际付款金额为充值金额+手续费</div><div id="qrcode" class="mt-3"></div></div><div class="card-body"><div class="d-flex align-items-center"><p class="my-auto ms-3">当前余额</p><p class="text-secondary text-sm ms-auto my-auto me-3">' +
									this.merchantInfo.usd_balance +
									' USD</p></div><div class="d-flex align-items-center"><p class="my-auto ms-3">需要充值</p><p class="text-secondary text-sm ms-auto my-auto me-3">' +
									rechargeAmount.toFixed(2) +
									' USD</p></div><div class="d-flex align-items-center"><p class="my-auto ms-3">充值金额</p><p class="text-secondary text-sm ms-auto my-auto me-3">' +
									result.pay_amount + ' ' + result.pay_currency +
									'</p></div><div class="d-flex align-items-center"><p class="my-auto ms-3">支付手续费</p><p class="text-secondary text-sm ms-auto my-auto me-3">' +
									result.pay_fee + ' ' + result.pay_currency +
									'</p></div></div>',
								customClass: {
									htmlContainer: "opacity-10",
									confirmButton: "btn bg-gradient-primary",
									cancelButton: "btn bg-gradient-default",
								},
								showCancelButton: true,
								cancelButtonText: "取消",
								confirmButtonText: "我已充值",
								buttonsStyling: false,
								didOpen: () => {
									this.isPay = false;
									this.timerGetRechargeOrder2PayBill(result.id);
								},
							}).then((result) => {
								if (result.isConfirmed) {
									this.$swal({
									  title: '付款查询中',
									  html: '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 请稍等片刻，正在查询付款是否到账',
									  customClass: {
										confirmButton: "btn bg-gradient-light",
									  },
									  confirmButtonText: "关闭",
									  buttonsStyling: false,
									});
								}
							});
							new QRCode("qrcode", {
								width: 200,
								height: 200,
								text: result.pay_url,
							});
						}).catch(err => {
							console.log(err)
						});
					} else if (err == "Bill paid") { // 账单已支付
						location.reload();
					}
				});
			},
			async timerGetRechargeOrder2PayBill(id) {
				clearInterval(this.rechargeTimer);
				this.rechargeTimer = setInterval(() => {
					this.$kuntnApi.getRechargeOrder({ // 获取充值订单详情
						id: id,
					}).then((result) => {
						if (result.recharge_status == 1) {
							this.$kuntnApi.payBill({ // 支付账单
								id: this.$route.params.id,
							}).then(() => {
								location.reload();
							}).catch(err => {
								console.log(err);
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}, 6000);
			},
			getTime(time) {
				return dateToString(time);
			},
			getDueTime(time1, time2) {
				return formateDueDate(time1, time2);
			},
		},
		mounted() {
			this.initializeData(); // 初始化数据
		},
	};
</script>